import React from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { useHistory } from "react-router";

const SponsorTableRow = ({ companyName, contact, email, phone, id, type }) => {
  const { deleteDocument } = useFirestore("sponsors");
  const history = useHistory();

  const editSponsor = () => {
    history.push(`/editSponsor/${id}`);
  };
  const deleteSponsor = () => {
    deleteDocument(id);
  };
  return (
    <tr className="table-light">
      <td>{companyName}</td>
      <td>{contact}</td>
      <td>{email}</td>
      <td>{phone}</td>
      {type === false && (
        <td>
          <button type="submit" className="btn btn-info" onClick={editSponsor}>
            Edit
          </button>
        </td>
      )}
      <td>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={deleteSponsor}
        >
          Delete
        </button>
      </td>
      {type && <td></td>}
    </tr>
  );
};

export default SponsorTableRow;
