import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { useDocument } from "../../hooks/useDocument";
import { useFirestore } from "../../hooks/useFirestore";
import moment from "moment";

import "./userModal.css";

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

const UserModal = ({ showModal, setShowModal, userUID }) => {
  const modalRef = useRef();
  const { document } = useDocument("users", userUID);

  const [status, setStatus] = useState("");
  const [adminText, setAdminText] = useState("Make Admin");
  const [buttonBackground, setButtonBackground] = useState("btn btn-success");
  const [inactiveButton, setInactiveButton] = useState("btn btn-warning");
  const { updateDocument } = useFirestore("users");

  useEffect(() => {
    if (document) {
      if (document.admin === true) {
        setAdminText("Remove Admin");
        setButtonBackground("btn btn-danger");
      }
      setStatus("Set to Inactive");
      console.log(document);
      if (document.dues === "inactive") {
        console.log("yes");
        setInactiveButton("btn btn-light");
        setStatus("Member needs to pay dues to re-activate");
      }
    }
  }, [document]);

  const handleSetInactive = () => {
    const update = {
      dues: "inactive",
    };
    console.log(userUID, update);
    updateDocument(userUID, update);
    setStatus("inactive");
    setInactiveButton("btn btn-light");
  };

  const handleSetDelete = () => {
    const update = {
      dues: "delete",
    };
    console.log(userUID, update);
    updateDocument(userUID, update);
    setStatus("delete");
    setInactiveButton("btn btn-light");
  };

  const handlePayDues = () => {
    let dues = {
      duesDue: moment().format("MMMM DD, YYYY"),
      dues: "paid",
    };

    updateDocument(userUID, dues);
  };

  const handleAdminClick = () => {
    if (adminText === "Make Admin") {
      setAdminText("Remove Admin");
      setButtonBackground("btn btn-danger");
      const update = {
        admin: true,
      };
      console.log(userUID, update);
      updateDocument(userUID, update);
    } else {
      setAdminText("Make Admin");
      setButtonBackground("btn btn-success");
      const update = {
        admin: false,
      };
      console.log(userUID, update);
      updateDocument(userUID, update);
    }
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  return (
    <>
      {showModal && document ? (
        <div className="background" onClick={closeModal} ref={modalRef}>
          <div className="modalWrapper" showModal={showModal}>
            <h1 className="contactHeader">
              {document.firstName} {document.lastName}
            </h1>
            {document.city && (
              <p>
                {document.city}, {document.state}
              </p>
            )}
            <p>
              {document.identity}, {document.race}, {document.gender}
            </p>
            {document.relation && <p>Relation: {document.relation} </p>}
            <p>Status: {document.dues}</p>
            <p>Dues due on: {document.duesDue}</p>
            <p>Account: {document.membership}</p>
            {document.military && (
              <p>
                Military: {document.military} {document.branch}
              </p>
            )}
            {document.jobFunction && <p>Job: {document.jobFunction}</p>}
            {document.jobStatus && <p>Job Status: {document.jobStatus}</p>}
            {document.business && <p>Business: {document.business} </p>}

            <h3 className="contactHeader">Contact Info:</h3>
            {document.phoneNumber && <p>Phone: {document.phoneNumber}</p>}
            <p>Email: {document.email}</p>
            <CloseModalButton
              aria-label="Close modal"
              onClick={() => setShowModal((prev) => !prev)}
            />
            <button
              type="submit"
              className={buttonBackground}
              onClick={() => handleAdminClick()}
              style={{ marginLeft: "8px" }}
            >
              {adminText}
            </button>
            <button
              type="submit"
              className={"btn btn-info"}
              onClick={() => handlePayDues()}
              style={{ display: "flex", marginLeft: "8px" }}
            >
              Mark Dues as Paid (will use today's date)
            </button>
            <button
              type="submit"
              className={inactiveButton}
              disabled={inactiveButton === "btn btn-light"}
              onClick={() => handleSetInactive()}
              style={{ marginLeft: "8px" }}
            >
              {status}
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              onClick={() => handleSetDelete()}
              style={{ marginLeft: "8px" }}
            >
              Delete
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserModal;
