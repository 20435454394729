import React from "react";

const RSVPTableRow = ({ firstName, lastName, email }) => {
  return (
    <tr className="table-light">
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{email}</td>
    </tr>
  );
};

export default RSVPTableRow;
