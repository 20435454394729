import React, { useState } from "react";
import moment from "moment";
import { useFirestore } from "../../hooks/useFirestore";
import { ImSpinner2 } from "react-icons/im";

function DonationCard({ onCancel }) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const [errorLabel, setErrorLabel] = useState("");
  const [loading, setLoading] = useState(false);

  const { addDocument } = useFirestore("donor");

  const validateForm = () => {
    if (email !== "") {
      if (amount !== "") {
        if (name !== "") {
          handleDonateNow();
        } else {
          setErrorLabel(
            "Name cannot be blank, please put anonymous if you wish to be unknown"
          );
        }
      } else {
        setErrorLabel("Amount cannot be blank");
      }
    } else {
      setErrorLabel("Email cannot be blank");
    }
  };

  const handleDonateNow = async () => {
    setLoading(true);
    console.log("checkout");

    const donation = {
      paymentDate: moment().format("MMMM DD, YYYY"),
      name,
      email,
      amount,
      message: message,
    };

    console.log(donation);
    await addDocument(donation);

    window.location = "https://donate.stripe.com/dR63fw8cf4fh8so5km";

    // const docRef = await db
    //   .collection("customers")
    //   .doc("QMcYJAdkjKevmzrk3fEsSgKHDfC3")
    //   .collection("checkout_sessions")
    //   .add({
    //     mode: "payment",
    //     price: "price_1MNkrRDCaUI4CQxONW7wgzEK",
    //     success_url: window.location.origin,
    //     cancel_url: "http://localhost:3000/postpayment/id/unknown/donation",
    //     paymentDate: moment().format("MMMM DD, YYYY"),
    //     name,
    //     email,
    //     amount,
    //     message: message,
    //   });
    // docRef.onSnapshot(async (snap) => {
    //   const { error, sessionId } = snap.data();
    //   if (error) {
    //     console.log("error");
    //     alert(error.message);
    //   }
    //   if (sessionId) {
    //     console.log("sessionId");
    //     const stripe = await loadStripe(
    //      process.env.REACT_APP_STRIPE_ACCESS
    //     );
    //     stripe.redirectToCheckout({ sessionId });
    //   }
    // });
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <p>
          Please fill out the donation form before continuing to the payment
          screen!
        </p>
        <form>
          <fieldset>
            <label className="form-label mt-4">Name</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="Your Name (Required)"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <label className="form-label mt-4">Email</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email (Required)"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <label className="form-label mt-4">Message</label>
            <input
              type="message"
              className="form-control"
              id="exampleMessage"
              aria-describedby="emailHelp"
              placeholder="Enter a message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />

            <label className="form-label mt-4">Amount</label>
            <input
              type="amount"
              className="form-control"
              id="exampleAmount"
              aria-describedby="emailHelp"
              placeholder="Enter Amount (Required)"
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
            />
          </fieldset>
        </form>
        <button
          type="submit"
          className="btn btn-info"
          onClick={validateForm}
          style={{ marginLeft: "8px" }}
          disabled={loading}
        >
          {loading && (
            <ImSpinner2
              icon="spinner"
              className="spinner"
              style={{
                marginRight: "5px",
              }}
            />
          )}
          {loading && <span>Processing Selection</span>}
          {!loading && <span>Checkout</span>}
        </button>

        <button
          type="submit"
          className="btn btn-danger"
          onClick={onCancel}
          style={{ marginLeft: "8px" }}
        >
          Cancel
        </button>

        {errorLabel !== "" && (
          <label style={{ marginLeft: "8px" }}>{"ERROR: " + errorLabel}</label>
        )}
      </div>
    </div>
  );
}

export default DonationCard;
