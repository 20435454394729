import React, { useEffect, useState } from "react";
import { useDocument } from "../../hooks/useDocument";
import { loadStripe } from "@stripe/stripe-js";
import db from "../../firebase/config";
import moment from "moment";
import { ImSpinner2 } from "react-icons/im";

const Dues = ({ userUID }) => {
  const { document } = useDocument("users", userUID);
  const [duesStatus, setDuesStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (document) {
      if (document.dues === "unpaid") {
        setDuesStatus(true);
      }

      const today = moment().format("MMMM DD, YYYY");
      if (moment(document.duesDue).isBefore(today)) {
        setDuesStatus(true);
      }
    }
  }, [document]);

  const handlePayNowAss = async () => {
    console.log("checkout");
    setLoading(true);
    const docRef = await db
      .collection("customers")
      .doc(userUID)
      .collection("checkout_sessions")
      .add({
        mode: "payment",
        price: process.env.REACT_APP_ASSOCIATE_LINK,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        console.log("error");
        alert(error.message);
      }
      if (sessionId) {
        console.log("sessionId");
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_ACCESS);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  const handlePayNowProf = async () => {
    setLoading(true);
    console.log("checkout");
    const docRef = await db
      .collection("customers")
      .doc(userUID)
      .collection("checkout_sessions")
      .add({
        mode: "payment",
        price: process.env.REACT_APP_PROFESSIONAL_LINK,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        console.log("error");
        alert(error.message);
      }
      if (sessionId) {
        console.log("sessionId");
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_ACCESS);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  return (
    <div className="user-list">
      <h2>GOAL CFL Membership</h2>
      {document && document.membership === "Associate" ? (
        <p> You are currently {"an associate member"}</p>
      ) : (
        <p> You are currently {"an professional member"}</p>
      )}
      {document && (
        <p>
          Next membership payment is due on:{" "}
          {moment(new Date(document.duesDue), "MMMM DD YYYY")
            .add(365, "d")
            .format("L")}
        </p>
      )}
      <h4 style={{ marginTop: "40px" }}>Membership Types: </h4>
      <p> An associate membership is open to anyone </p>
      <p>
        A professional membership is for past or present full-time law
        enforcement officer or full-time employee of an agency of the criminal
        justice system
      </p>
      {duesStatus && (
        <>
          <button
            type="submit"
            className="btn btn-success"
            style={{ marginBottom: "15px", marginRight: "12px" }}
            disable={loading}
            onClick={
              document & (document.membership === "Associate")
                ? handlePayNowAss
                : handlePayNowProf
            }
          >
            {loading && (
              <ImSpinner2
                icon="spinner"
                className="spinner"
                style={{
                  marginRight: "5px",
                }}
              />
            )}
            {loading && <span>Processing Selection</span>}
            {!loading && <span>Renew Membership</span>}
          </button>
          <button
            type="submit"
            className="btn btn-warning"
            style={{ marginBottom: "15px" }}
            disabled={loading}
            onClick={
              document & (document.membership === "Associate")
                ? handlePayNowProf
                : handlePayNowAss
            }
          >
            {loading && (
              <ImSpinner2
                icon="spinner"
                className="spinner"
                style={{
                  marginRight: "5px",
                }}
              />
            )}
            {loading && <span>Processing Selection</span>}
            {!loading && <span>Change Membership Type</span>}
          </button>
        </>
      )}
    </div>
  );
};

export default Dues;
