import React from "react";
import { Row } from "react-bootstrap";

const Bronze = () => {
  return (
    <div>
      <div className="grid-container">
        <div
          className="card border-light mt-3 mb-3"
          style={{
            maxWidth: "30rem",
            backgroundColor: "rgb(233,153,118)",
            height: "530px",
          }}
        >
          <div className="card-header" style={{ fontSize: "28px" }}>
            Bronze Sponsor{" "}
            <Row style={{ fontSize: "20px", paddingLeft: "10px" }}>($500)</Row>
          </div>
          <div className="card-body">
            <p className="card-text">
              <dl>
                <dt> For 1 Year, Corporate Name and Logo on: </dt>
                <dd>Website in Corporate Sponsors section</dd>
                <dt>Social Media</dt>
                <dd> Donor acknowledgement</dd>

                <dt>Community</dt>
                <dd>
                  2 Memberships
                  <br></br>2 T-shirts
                </dd>
              </dl>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bronze;
