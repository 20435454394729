import React from "react";
import { useCollection } from "../../hooks/useCollection";
import EventSugRow from "./EventSugRow";

const EventSugTable = () => {
  const { isPending, error, documents } = useCollection("eventSuggestion");

  return (
    <div className="user-list">
      <h2>Event Suggestions</h2>
      {isPending && <div>Loading Event Suggestions...</div>}
      {error && <div>{error}</div>}
      {documents && (
        <table className="table table-hover">
          <thead>
            <tr className="table-dark">
              <th scope="col">Event Name</th>
              <th scope="col">Description</th>
              <th scope="col">Contact Person</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Website</th>
              <th></th>
            </tr>
          </thead>
          {documents.map((event) => (
            <tbody key={event.id} className="user-list-item">
              <EventSugRow
                eventName={event.eventName}
                eventDescription={event.eventDescription}
                eventContact={event.eventContact}
                eventPhone={event.eventPhone}
                website={event.eventWebsite}
                id={event.id}
                type={false}
              />
            </tbody>
          ))}
        </table>
      )}
    </div>
  );
};

export default EventSugTable;
