import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useFirestore } from "../hooks/useFirestore";

const PostPayment = () => {
  const { status, userUID, type } = useParams();
  const { updateDocument } = useFirestore("users");
  const [count, setCount] = useState(false);

  useEffect(() => {
    if (count === false) {
      if (status === "success") {
        let user = {
          duesDue: moment().format("MMMM DD, YYYY"),
          membership: type,
          dues: "paid",
        };

        updateDocument(userUID, user);
        setCount(true);
      }
    }
  }, [count, status, type, updateDocument, userUID]);

  return (
    <>
      {status === "success" ? (
        <>
          <h3
            className="badge rounded-pill bg-success"
            style={{ fontSize: "20px" }}
          >
            Thank you for your membership payment!
          </h3>
        </>
      ) : (
        <>
          <h2>
            We are sorry, but we are unable to process your payment at this
            time.
          </h2>
          <h2>Please try again later or contact the board</h2>
        </>
      )}
    </>
  );
};

export default PostPayment;
