import React, { useEffect, useState } from "react";
import { useCollection } from "../../hooks/useCollection";
import { useHistory } from "react-router";
import MemberTableRow from "./MemberTableRow";
import { CopyToClipboard } from "react-copy-to-clipboard";

const MemberTable = ({ handleViewProfile }) => {
  const { isPending, error, documents } = useCollection("users");
  const [emailState, setEmailState] = useState([]);
  const [emailCopied, setEmailCopied] = useState(false);
  const [allUsers, setAllUsers] = useState();
  const history = useHistory();

  useEffect(() => {
    if (documents) {
      let test = [];
      documents.map((user) => {
        if (user.dues !== "delete") {
          test.push(user);
        }
      });

      setAllUsers(test);
    }
  }, [documents]);

  useEffect(() => {
    let emailArray = [];
    if (allUsers) {
      allUsers.map(
        (user) => user.dues !== "inactive" && emailArray.push(user.email)
      );
      setEmailState(emailArray);
    }
  }, [allUsers]);

  const handleCopyEmial = () => {
    console.log(emailState);
    setEmailCopied(true);
  };

  return (
    <div className="user-list">
      <h2>All Members</h2>
      <button
        type="submit"
        className="btn btn-success"
        style={{ marginBottom: "15px" }}
        onClick={() => history.push("/notificationForm")}
      >
        Send Notification to All
      </button>
      <CopyToClipboard text={emailState}>
        <button
          type="submit"
          className="btn btn-success"
          style={{ marginBottom: "15px", marginLeft: "15px" }}
          onClick={() => handleCopyEmial()}
        >
          {emailCopied ? "Copied!!!" : "Copy All Email Addresses"}
        </button>
      </CopyToClipboard>
      {isPending && <div>Loading users...</div>}
      {error && <div>{error}</div>}
      {allUsers && (
        <table className="table table-hover">
          <thead>
            <tr className="table-dark">
              <th scope="col">Member Id</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Account Type</th>
              <th scope="col">Next Dues</th>
              <th></th>
            </tr>
          </thead>
          {allUsers.map((user) => (
            <tbody key={user.id} className="user-list-item">
              <MemberTableRow
                email={user.email}
                memberId={user.memberId}
                id={user.uid}
                firstName={user.firstName}
                lastName={user.lastName}
                admin={user.admin}
                board={user.board}
                dues={user.dues}
                handleOnClick={handleViewProfile}
              />
            </tbody>
          ))}
        </table>
      )}
    </div>
  );
};

export default MemberTable;
