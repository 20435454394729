import React from "react";
import { Carousel } from "react-bootstrap";
import { useCollection } from "../../hooks/useCollection";

import "./PhotoCarousel.css";

const PhotoCarousel = () => {
  const { documents } = useCollection("photos");

  console.log(documents);

  return (
    <>
      {documents && (
        <Carousel
          style={{ width: "50%", marginLeft: "23%", marginBottom: "8px" }}
          className="carousel"
        >
          <Carousel.Item interval={1000}>
            <img
              className="d-block w-100"
              src={documents[0].url}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={1000}>
            <img
              className="d-block w-100"
              src={documents[1].url}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={documents[2].url}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      )}
    </>
  );
};

export default PhotoCarousel;
