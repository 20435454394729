import React from "react";
import { Row } from "react-bootstrap";

const First = () => {
  return (
    <div className="grid-container">
      <div className="card mb-3 mt-3" style={{ maxWidth: "30rem" }}>
        <div className="card-header" style={{ fontSize: "20px" }}>
          Support active duty and retired LGBTQ criminal justice professionals
          and other personnel of recognized law enforcement institutions
          <Row style={{ fontSize: "20px", paddingLeft: "10px" }}></Row>
        </div>
        <div className="card-body">
          <p className="card-text">
            <ul>
              <li>Membership Meetings</li>
              <li>Quarterly Social Events</li>
              <li>Advocacy Services</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default First;
