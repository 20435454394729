import React, { useState } from "react";
import { useCollection } from "../../hooks/useCollection";
import HelpRequestForm from "../HelpRequestForm/HelpRequestForm";
import FilterHelp from "../Filter/FilterHelp";
import { useFirestore } from "../../hooks/useFirestore";

const HelpRequest = () => {
  const { documents } = useCollection("assistance");
  const { updateDocument } = useFirestore("assistance");
  const [filter, setFilter] = useState("All");

  let sorted = [];

  if (documents) {
    sorted = documents.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
  }

  const changeFilter = (newFilter) => {
    setFilter(newFilter);
  };

  const approve = async (id) => {
    const update = {
      status: "Approved",
    };
    await updateDocument(id, update);
  };

  const reject = async (id) => {
    const update = {
      status: "Rejected",
    };
    await updateDocument(id, update);
  };

  console.log(documents);
  return (
    <div>
      <h2>Requests for Aid</h2>
      <h3>
        Currently Displaying{" "}
        {filter === "All" ? "All Request" : `Request that are ${filter}`}
      </h3>
      <FilterHelp changeFilter={changeFilter} />
      {documents && documents.length === 0 && (
        <h5 style={{ marginTop: "25px", color: "teal" }}>
          There are no applications
        </h5>
      )}
      {documents &&
        sorted.map((document, index) =>
          filter !== "All" ? (
            document.status === filter && (
              <div key={index} style={{ paddingTop: "12px" }}>
                <HelpRequestForm
                  document={document}
                  onApprove={() => approve(document.id)}
                  onReject={() => reject(document.id)}
                />
              </div>
            )
          ) : (
            <>
              <div key={index} style={{ paddingTop: "12px" }}>
                <HelpRequestForm
                  document={document}
                  onApprove={() => approve(document.id)}
                  onReject={() => reject(document.id)}
                />
              </div>
            </>
          )
        )}
    </div>
  );
};

export default HelpRequest;
