import React from "react";

const SponsorCard = ({ website, logoURL }) => {
  return (
    <div>
      <div>
        <img
          src={logoURL}
          alt="company-logo"
          style={{
            padding: "10px",
            backgroundColor: "rgb(238, 238, 238)",
            marginBottom: "20px",
          }}
        />
        <a style={{ marginLeft: "20px" }} href={website} className="card-link">
          Visit Website
        </a>
      </div>
    </div>
  );
};
export default SponsorCard;
