import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirestore } from "../hooks/useFirestore";
import { projectStorage } from "../firebase/config";
import { useDocument } from "../hooks/useDocument";
import { useParams } from "react-router-dom";
import "./Membership/memberForm.css";
import { ImSpinner2 } from "react-icons/im";

function ProductForm() {
  const history = useHistory();
  const { id } = useParams();
  const { document } = useDocument("store", id);

  const { addDocument, updateDocument, response } = useFirestore("store");
  const [productName, setProdcutName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productQty, setProductQty] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productStatus, setProductStatus] = useState("");
  const [errorLabel, setErrorLabel] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);
  const [type, setType] = useState("Upload");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (document) {
      setProdcutName(document.productName);
      setProductDescription(document.productDescription);
      setProductQty(document.productQty);
      setProductPrice(document.productPrice);
      setProductStatus(document.productStatus);
      setType("Update");
    }
  }, [document]);

  const handleSelectionMade = (e) => {
    setProductStatus(e.target.value);
  };
  const handleFileChange = (e) => {
    setThumbnail(null);
    let selected = e.target.files[0];

    if (!selected) {
      setThumbnailError("Please select a file");
      return;
    }
    if (!selected.type.includes("image")) {
      setThumbnailError("Selected file must be an image");
      return;
    }

    setThumbnailError(null);
    setThumbnail(selected);
    console.log("thumbnail updated");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (productName !== "") {
      if (setProductDescription !== "") {
        if (productQty !== "") {
          if (productPrice !== "") {
            if (productStatus !== "") {
              setLoading(true);
              if ((thumbnail !== null) | (id !== null)) {
                let imgUrl = "";
                if (thumbnail !== null) {
                  const uploadPath = `store/${thumbnail.name}`;
                  const img = await projectStorage
                    .ref(uploadPath)
                    .put(thumbnail);
                  imgUrl = await img.ref.getDownloadURL();
                }

                let product = {};

                if (type === "Upload") {
                  product = {
                    productName,
                    productDescription,
                    productQty,
                    productStatus,
                    productPrice,
                    eventURL: imgUrl,
                  };

                  await addDocument(product);
                  if (!response.error) {
                    setLoading(false);
                  }
                } else {
                  if (thumbnail === null) {
                    imgUrl = document.eventURL;
                  }

                  product = {
                    productName,
                    productDescription,
                    productQty,
                    productStatus,
                    productPrice,
                    eventURL: imgUrl,
                  };

                  await updateDocument(id, product);
                  if (!response.error) {
                    setLoading(false);
                  }
                }

                if (!response.error) {
                  history.push("/dashboard");
                }
              } else {
                setErrorLabel("ERROR: Please upload product image!");
              }
            } else {
              setErrorLabel("ERROR: Please select product status!");
            }
          } else {
            setErrorLabel("ERROR: Please enter prodcut price!");
          }
        } else {
          setErrorLabel("ERROR: Please enter product quantity!");
        }
      } else {
        setErrorLabel("ERROR: Please enter product description!");
      }
    } else {
      setErrorLabel("ERROR: Please enter a product name!");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Product Details:</h4>
        <p>All fields are required</p>
        <form>
          <fieldset>
            <div className="form-group">
              <label className="form-label mt-4">Product Name</label>
              <input
                type="text"
                className="form-control"
                id="productName"
                placeholder="Product Name (Required)"
                onChange={(e) => setProdcutName(e.target.value)}
                value={productName}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Product Description</label>
              <input
                type="text"
                className="form-control"
                id="productDescription"
                placeholder="Product Description (Required)"
                onChange={(e) => setProductDescription(e.target.value)}
                value={productDescription}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Product Quantity</label>
              <input
                type="text"
                className="form-control"
                id="productQty"
                placeholder="Product Qty (numbers only)"
                onChange={(e) => setProductQty(e.target.value)}
                value={productQty}
              />
              <div className="form-group">
                <label className="form-label mt-4">Product Price</label>
                <input
                  type="text"
                  className="form-control"
                  id="productPrice"
                  placeholder="Product Price (do not add $)"
                  onChange={(e) => setProductPrice(e.target.value)}
                  value={productPrice}
                />
              </div>
              <div className="form-group">
                <label className="form-label mt-4">Product Status</label>
                <select
                  className="form-select"
                  id="exampleSelect1"
                  onChange={handleSelectionMade}
                >
                  <option value="Available">Available</option>
                  <option value="Unavailable">Unavailable</option>
                  <option value="Pre-Order">Pre-Order</option>
                </select>
              </div>

              <label>
                <span>Product Image: </span>
                <input required type="file" onChange={handleFileChange} />
                {thumbnailError && (
                  <div className="error">{thumbnailError}</div>
                )}
              </label>

              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading && (
                  <ImSpinner2
                    icon="spinner"
                    className="spinner"
                    style={{
                      marginRight: "5px",
                    }}
                  />
                )}
                {loading && <span>...saving</span>}
                {!loading && <span>{type}</span>}
              </button>
              <label>{errorLabel}</label>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default ProductForm;
