import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./style.css";
import { ImSpinner2 } from "react-icons/im";
import { useDocument } from "../../../hooks/useDocument";
import { useFirestore } from "../../../hooks/useFirestore";
import { useCollection } from "../../../hooks/useCollection";

const MemberHelp = ({ userUID }) => {
  const { addDocument, response } = useFirestore("assistance");
  const { updateDocument } = useFirestore("users");
  const { document } = useDocument("users", userUID);
  const { documents } = useCollection("assistance");
  const [usersDocs, setUsersDocs] = useState([]);

  const [formSubmitted, setFormSubmitted] = useState("start");
  const [submissionStatus, setSubmissionStatus] = useState("n/a");
  const [submitDate, setSubmitDate] = useState("n/a");

  const [legalName, setLegalName] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [pronouns, setPronous] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [lgbt, setlgbt] = useState("No");
  const [employeed, setEmployeed] = useState("No");
  const [jobLocation, setJobLocation] = useState("");
  const [jobSafe, setJobSafe] = useState("");
  const [emergency, setEmergency] = useState("No");
  const [explaination, setExplaination] = useState("");
  const [amountRequested, setAmountRequested] = useState("");
  const [reasoning, setReasoning] = useState("");
  const [previousAssistance, setPreviousAssistance] = useState("No");

  const [errorLabel, setErrorLabel] = useState("");
  const [loading, setLoading] = useState(false);

  const form = useRef();

  useEffect(() => {
    if (document) {
      setEmail(document.email);
      setPhoneNumber(document.phoneNumber);
      const legalName = document.firstName + " " + document.lastName;
      setLegalName(legalName);
      setPreferredName(document.firstName);

      let usersDocs = [];

      if (document.assistance) {
        if (documents) {
          documents.map((assDoc) => {
            if (assDoc.user === userUID) {
              const obj = {
                ...assDoc,
                date: new Date(
                  assDoc.createdAt.seconds * 1000 +
                    assDoc.createdAt.nanoseconds / 1000000
                ),
              };
              usersDocs.push(obj);
            }
          });

          setUsersDocs(usersDocs);
        }
      }
    }
  }, [document, documents, userUID]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (legalName !== "") {
      if (preferredName !== "") {
        if (pronouns !== "") {
          if (dob !== "") {
            if (phoneNumber !== "") {
              if (email !== "") {
                if (lgbt !== "") {
                  if (employeed !== "") {
                    if (employeed === "No" || jobLocation !== "") {
                      if (employeed === "No" || jobSafe !== "") {
                        if (emergency !== "") {
                          if (emergency === "No" || explaination !== "") {
                            if (amountRequested !== "") {
                              if (reasoning !== "") {
                                setLoading(true);
                                const memberForm = {
                                  legalName,
                                  preferredName,
                                  pronouns,
                                  dob,
                                  phoneNumber,
                                  email,
                                  lgbt,
                                  employeed,
                                  jobLocation,
                                  jobSafe,
                                  emergency,
                                  explaination,
                                  amountRequested,
                                  reasoning,
                                  previousAssistance,
                                  status: "Submitted",
                                  user: userUID,
                                };

                                await addDocument(memberForm);
                                if (!response.error) {
                                  setFormSubmitted("complete");
                                  setSubmitDate();
                                  setSubmissionStatus("Submitted");

                                  //Update the user
                                  const userUpdate = {
                                    assistance: "submitted",
                                  };

                                  await updateDocument(userUID, userUpdate);

                                  setLoading(false);
                                }
                              } else {
                                setErrorLabel(
                                  "ERROR: Please enter what the money will be used for"
                                );
                              }
                            } else {
                              setErrorLabel(
                                "ERROR: Please enter the amount being requested"
                              );
                            }
                          } else {
                            setErrorLabel(
                              "ERROR: Please enter an description of your situation"
                            );
                          }
                        } else {
                          setErrorLabel(
                            "ERROR: Please select if thhis is an emergency situation"
                          );
                        }
                      } else {
                        setErrorLabel(
                          "ERROR: Please select if your employeer is a Safe Place Initiative"
                        );
                      }
                    } else {
                      setErrorLabel("ERROR: Please enter your employeer");
                    }
                  } else {
                    setErrorLabel("ERROR: Please select if you are employeed");
                  }
                } else {
                  setErrorLabel(
                    "ERROR: Please state if you are part of the LGBTQ+ community"
                  );
                }
              } else {
                setErrorLabel("ERROR: Please enter your email");
              }
            } else {
              setErrorLabel("ERROR: Please enter a phone number");
            }
          } else {
            setErrorLabel("ERROR: Please enter your date of birth");
          }
        } else {
          setErrorLabel("ERROR: Please enter your preferred pronouns");
        }
      } else {
        setErrorLabel("ERROR: Please enter preferred name");
      }
    } else {
      setErrorLabel("ERROR: Please enter your legal name");
    }
  };

  const handleSendEmail = (e) => {
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID_NEW_ASSISTANCE,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then((error) => {
        console.log(error.text);
      });
  };

  const handleClearForm = () => {
    setSubmissionStatus("n/a");
    setSubmitDate("n/a");
    setLegalName("");
    setPreferredName("");
    setPronous("");
    setDob("");
    setPhoneNumber("");
    setEmail("");
    setEmployeed("");
    setlgbt("No");
    setJobLocation("");
    setJobSafe("");
    setEmergency("No");
    setExplaination("");
    setAmountRequested("");
    setReasoning("");
    setPreviousAssistance("No");
    setErrorLabel("");
  };

  return (
    <div>
      <h2>Welcome to the Member Help Page</h2>
      <p>You can submit this form for finanical assistance</p>
      {documents && <h3>Your Submitted Forms:</h3>}
      {usersDocs.length > 0 &&
        usersDocs.map((form) => (
          <div style={{ display: "flex" }}>
            <div>{form.date.toString()}</div>
            <div style={{ marginLeft: "12px" }}>{form.reasoning}</div>
            <div style={{ marginLeft: "12px" }}>{form.status}</div>
          </div>
        ))}

      {formSubmitted !== "ready" && (
        <button
          type="submit"
          className="btn btn-success"
          style={{ marginBottom: "15px" }}
          onClick={() => {
            setFormSubmitted("ready");
            handleClearForm();
          }}
        >
          Fill Out Application
        </button>
      )}
      {formSubmitted === "complete" ? (
        <>
          <p>
            Thank you for your application, someone will contact you shortly
          </p>

          <p>Submitted on: {submitDate}</p>
          <p>Current status: {submissionStatus}</p>
        </>
      ) : (
        formSubmitted === "ready" && (
          <>
            <div className="formStyle">
              <form ref={form} onSubmit={handleSendEmail}>
                <fieldset>
                  <div className="form-group">
                    <label className="form-label mt-4">Legal Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="legalName"
                      name="user_legalname"
                      placeholder="Legal Name (Required)"
                      onChange={(e) => setLegalName(e.target.value)}
                      value={legalName}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label mt-4">Preferred Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="preferred"
                      name="user_preferredName"
                      placeholder="Preferred Name (Required)"
                      onChange={(e) => setPreferredName(e.target.value)}
                      value={preferredName}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label mt-4">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email (Required)"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                  </div>
                  <div className="form-group">
                    <label className="form-label mt-4">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phonenumber"
                      placeholder="Phone Number (Required or N/A)"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label mt-4">Your Pronouns</label>
                    <input
                      type="text"
                      className="form-control"
                      id="pronouns"
                      placeholder="Preferred Pronouns (Required)"
                      onChange={(e) => setPronous(e.target.value)}
                      value={pronouns}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label mt-4">Date of Birth</label>
                    <input
                      type="text"
                      className="form-control"
                      id="dateBirth"
                      placeholder="Date of Birth (Required)"
                      onChange={(e) => setDob(e.target.value)}
                      value={dob}
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label mt-4">
                      Are you a member or ally of the LGBTQ+ Community?
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      id="lgbtq"
                      placeholder="Your answer (Required)"
                      onChange={(e) => setlgbt(e.target.value)}
                      value={lgbt}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label className="form-label mt-4">
                      Are you currently employeed?
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      id="employed"
                      placeholder="Your answer (Required)"
                      onChange={(e) => setEmployeed(e.target.value)}
                      value={employeed}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </select>
                  </div>

                  {employeed === "Yes" && (
                    <>
                      <div className="form-group">
                        <label className="form-label mt-4">
                          Where do you Work?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="jobLocation"
                          placeholder="Employeer (Required)"
                          onChange={(e) => setJobLocation(e.target.value)}
                          value={jobLocation}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label mt-4">
                          Is your employeer a member of the Safe Place
                          Initiative?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="safe"
                          placeholder="Employeer Safe Place Initiative (Required or N/A)"
                          onChange={(e) => setJobSafe(e.target.value)}
                          value={jobSafe}
                        />
                      </div>
                    </>
                  )}

                  <div className="form-group">
                    <label className="form-label mt-4">
                      Is this an emergency situation?
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      id="emergency"
                      placeholder="Your answer (Required)"
                      onChange={(e) => setEmergency(e.target.value)}
                      value={emergency}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </select>
                  </div>

                  {emergency === "Yes" && (
                    <div className="form-group">
                      <label className="form-label mt-4">
                        Please breifly explian your curent situation
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="explaination"
                        placeholder="(Required)"
                        onChange={(e) => setExplaination(e.target.value)}
                        value={explaination}
                      />
                    </div>
                  )}

                  <div className="form-group">
                    <label className="form-label mt-4">
                      How much money are you requesting?
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="amount"
                      placeholder="Your answer (Required)"
                      onChange={(e) => setAmountRequested(e.target.value)}
                      value={amountRequested}
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label mt-4">
                      What will the money be used for?
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="reasoning"
                      placeholder="(Required)"
                      onChange={(e) => setReasoning(e.target.value)}
                      value={reasoning}
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label mt-4">
                      Have you previously submitted a request for help from
                      GOALCFL?
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      id="previousAssistance"
                      placeholder="Your answer (Required)"
                      onChange={(e) => setPreviousAssistance(e.target.value)}
                      value={previousAssistance}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </select>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading && (
                      <ImSpinner2
                        icon="spinner"
                        className="spinner"
                        style={{
                          marginRight: "5px",
                        }}
                      />
                    )}
                    {loading && <span>Submitting Form</span>}
                    {!loading && <span>Submit</span>}
                  </button>
                </fieldset>
                <label style={{ color: "red" }}>{errorLabel}</label>
              </form>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default MemberHelp;
