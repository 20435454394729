import React from "react";
import { useHistory } from "react-router";
import { useCollection } from "../../hooks/useCollection";
import SponsorTableRow from "./SponsorTableRow";

const SponsorTable = () => {
  const { isPending, error, documents } = useCollection("sponsors");
  const history = useHistory();
  return (
    <div className="user-list">
      <h2>All Sponsors</h2>
      <button
        type="submit"
        className="btn btn-success"
        style={{ marginBottom: "15px" }}
        onClick={() => history.push("/sponsorSignUp")}
      >
        Add Sponsor
      </button>
      {isPending && <div>Loading Sponsors...</div>}
      {error && <div>{error}</div>}
      {documents && (
        <table className="table table-hover">
          <thead>
            <tr className="table-dark">
              <th scope="col">Company Name</th>
              <th scope="col">Contact</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {documents.map((sponsor) => (
            <tbody key={sponsor.id} className="user-list-item">
              <SponsorTableRow
                companyName={sponsor.companyName}
                contact={sponsor.contactName}
                email={sponsor.email}
                phone={sponsor.phoneNumber}
                id={sponsor.id}
                type={false}
              />
            </tbody>
          ))}
        </table>
      )}
    </div>
  );
};

export default SponsorTable;
