import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import FooterStyle from "./FooterStyle";

const Footer = () => {
  const [fbHover, setfbHover] = useState(false);
  const [twHover, settwHover] = useState(false);
  const [inHover, setinHover] = useState(false);

  return (
    <footer>
      <Container style={{ marginTop: "50px" }}>
        <BsFacebook
          style={{
            fontSize: "60px",
            ...(fbHover ? FooterStyle.hover : FooterStyle.normal),
            cursor: "pointer",
          }}
          onMouseEnter={() => {
            setfbHover(true);
          }}
          onMouseLeave={() => {
            setfbHover(false);
          }}
          onClick={() =>
            (window.location.href = "https://www.facebook.com/GOALcfl")
          }
        />
        <BsInstagram
          style={{
            fontSize: "60px",
            marginLeft: "20px",
            ...(inHover ? FooterStyle.hover : FooterStyle.normal),
            cursor: "pointer",
          }}
          onMouseEnter={() => {
            setinHover(true);
          }}
          onMouseLeave={() => {
            setinHover(false);
          }}
          onClick={() =>
            (window.location.href = "https://www.instagram.com/goalcfl/")
          }
        />
        <BsTwitter
          style={{
            fontSize: "60px",
            marginLeft: "20px",
            ...(twHover ? FooterStyle.hover : FooterStyle.normal),
            cursor: "pointer",
          }}
          onMouseEnter={() => {
            settwHover(true);
          }}
          onMouseLeave={() => {
            settwHover(false);
          }}
          onClick={() => (window.location.href = "https://twitter.com/GOALcfl")}
        />
        <Row>
          <Col className="text-center py-3">Copyright &copy;2023 GOALcfl</Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
