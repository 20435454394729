/* TODO
Determine where to put "Submit your info"
*/

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Platinum from "../../components/SponsorMemberships.js/Platinum";
import Gold from "../../components/SponsorMemberships.js/Gold";
import Silver from "../../components/SponsorMemberships.js/Silver";
import Bronze from "../../components/SponsorMemberships.js/Bronze";
import { useHistory } from "react-router";

import "./BecomeSponsor.css";
import DonationCard from "../../components/DonationCard/DonationCard";

const NewSponsor = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="container">
      {showModal && <DonationCard onCancel={() => setShowModal(false)} />}
      {!showModal && (
        <>
          <div className="headerInfo">
            <h1>GOALcfl Sponsorship Opportunities</h1>
          </div>
          <button
            type="submit"
            className="btn btn-info"
            onClick={() => history.push("/submitInfo")}
          >
            Submit Your Information
          </button>
          <button
            type="submit"
            className="btn btn-warning"
            onClick={() => setShowModal(true)}
            style={{ marginLeft: "8px" }}
          >
            Donate Now!
          </button>
          <Row>
            <Col sm={12} md={6} lg={4} xl={3}>
              <Platinum />
            </Col>
            <Col sm={12} md={6} lg={4} xl={3}>
              <Gold />
            </Col>
            <Col sm={12} md={6} lg={4} xl={3}>
              <Silver />
            </Col>
            <Col sm={12} md={6} lg={4} xl={3}>
              <Bronze />
            </Col>
          </Row>
          <Link to="CH72086ComplianceLetter.pdf" target="_blank" download>
            Download Our Compliance Letter
          </Link>
        </>
      )}
    </div>
  );
};
export default NewSponsor;
