import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirestore } from "../hooks/useFirestore";
import "./Membership/memberForm.css";
import { ImSpinner2 } from "react-icons/im";

function SuggestEvent() {
  const history = useHistory();

  const { addDocument, response } = useFirestore("eventSuggestion");
  const [eventName, setEventName] = useState("");
  const [eventContact, setEventContact] = useState("");
  const [eventPhone, setEventPhone] = useState("");
  const [errorLabel, setErrorLabel] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventWebsite, setEventWebsite] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (eventName !== "") {
      if (eventContact !== "") {
        if (eventPhone !== "") {
          if (eventDescription !== "") {
            setLoading(true);
            const event = {
              eventName,
              eventContact,
              eventPhone,
              eventDescription,
              eventWebsite,
            };

            await addDocument(event);

            if (!response.error) {
              setLoading(false);
              history.push("/");
            }
          } else {
            setErrorLabel("ERROR: Please enter event description!");
          }
        } else {
          setErrorLabel(
            "ERROR: Please a phone number for the contact (or your phone number)!"
          );
        }
      } else {
        setErrorLabel("ERROR: Please add a contact person (or your name)!");
      }
    } else {
      setErrorLabel("ERROR: Please add event name!");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Suggest a New Event</h4>
        <p>All fields are required (use N/A if not applicable)</p>
        <form>
          <div className="form-group">
            <label className="form-label mt-4">Event Name</label>
            <input
              type="text"
              className="form-control"
              id="eventName"
              placeholder="Event Name (Required)"
              onChange={(e) => setEventName(e.target.value)}
              value={eventName}
            />
          </div>
          <div className="form-group">
            <label className="form-label mt-4">
              Event Contact Person (or your name)
            </label>
            <input
              type="text"
              className="form-control"
              id="eventContact"
              placeholder="Contact Person Name (Required)"
              onChange={(e) => setEventContact(e.target.value)}
              value={eventContact}
            />
          </div>
          <div className="form-group">
            <label className="form-label mt-4">
              Contact Phone Number (or your phone number)
            </label>
            <input
              type="text"
              className="form-control"
              id="eventPhone"
              placeholder="Phone Number (Required)"
              onChange={(e) => setEventPhone(e.target.value)}
              value={eventPhone}
            />
            <div className="form-group">
              <label className="form-label mt-4">Event Description</label>
              <input
                type="text"
                className="form-control"
                id="description"
                placeholder="Event Description (Required)"
                value={eventDescription}
                onChange={(e) => setEventDescription(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Event Website</label>
              <input
                type="text"
                className="form-control"
                id="website"
                placeholder="Event Website (Required)"
                value={eventWebsite}
                onChange={(e) => setEventWebsite(e.target.value)}
              />
            </div>
          </div>
        </form>

        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <ImSpinner2
              icon="spinner"
              className="spinner"
              style={{
                marginRight: "5px",
              }}
            />
          )}
          {loading && <span>...saving</span>}
          {!loading && <span>Submit</span>}
        </button>
        <label>{errorLabel}</label>
      </div>
    </div>
  );
}

export default SuggestEvent;
