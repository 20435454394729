import React, { useState } from "react";
import "./Membership/memberForm.css";
import { useHistory } from "react-router-dom";
import { projectStorage } from "../firebase/config";
import { useFirestore } from "../hooks/useFirestore";
import { ImSpinner2 } from "react-icons/im";

function UserProfileForm({ uid }) {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { updateDocument, response } = useFirestore("users");

  const handleFileChange = (e) => {
    setThumbnail(null);
    let selected = e.target.files[0];
    console.log(selected);

    if (!selected) {
      setThumbnailError("Please select a file");
      return;
    }
    if (!selected.type.includes("image")) {
      setThumbnailError("Selected file must be an image");
      return;
    }

    setThumbnailError(null);
    setThumbnail(selected);
    console.log("thumbnail updated");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const uploadPath = `users/${thumbnail.name}`;
    const img = await projectStorage.ref(uploadPath).put(thumbnail);
    const imgUrl = await img.ref.getDownloadURL();

    const profile = {
      firstName: firstName,
      lastName: lastName,
      address,
      state,
      city,
      zipCode,
      pictureURL: imgUrl,
    };

    await updateDocument(uid, profile);
    if (!response.error) {
      setLoading(false);
      history.push("/dashboard");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Join Now</h4>
        <form>
          <fieldset>
            <div className="form-group">
              <label className="form-label mt-4">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Street Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="Streeet Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">State</label>
              <select
                className="form-select"
                id="exampleSelect1"
                onChange={(e) => setState(e.target.value)}
              >
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Zip Code</label>
              <input
                type="text"
                className="form-control"
                id="zip"
                placeholder="Zip Code"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>
            <fieldset className="form-group">
              <legend className="mt-4">Select Membership Type:</legend>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios"
                  id="optionsRadios1"
                  value="option1"
                />
                <label className="form-check-label" for="optionsRadios1">
                  Professional Membership - past or present full-time law
                  enforcement officer or full-time employee of an agency of the
                  criminal justice system
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios"
                  id="optionsRadios2"
                  value="option2"
                />
                <label className="form-check-label" for="optionsRadios2">
                  Associate Membership - open to anyone!
                </label>
              </div>
            </fieldset>

            <label>
              <span>Profile Picture: </span>
              <input required type="file" onChange={handleFileChange} />
              {thumbnailError && <div className="error">{thumbnailError}</div>}
            </label>

            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading && (
                <ImSpinner2
                  icon="spinner"
                  className="spinner"
                  style={{
                    marginRight: "5px",
                  }}
                />
              )}
              {loading && <span>...saving</span>}
              {!loading && <span>Save</span>}
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default UserProfileForm;
