import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirestore } from "../hooks/useFirestore";
import { projectStorage } from "../firebase/config";
import { useDocument } from "../hooks/useDocument";
import { useParams } from "react-router-dom";
import "./Membership/memberForm.css";
import { ImSpinner2 } from "react-icons/im";

function EventForm() {
  const history = useHistory();
  const { id } = useParams();
  const { document } = useDocument("events", id);

  const { addDocument, updateDocument, response } = useFirestore("events");
  const [eventName, setEventName] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [errorLabel, setErrorLabel] = useState("");
  const [eventMonth, setEventMonth] = useState("");
  const [rsvp, setRSVP] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);
  const [eventDescription, setEventDescription] = useState("");
  const [type, setType] = useState("Create");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (document) {
      setEventName(document.eventName);
      setEventTime(document.eventTime);
      setEventDescription(document.eventDescription);
      setEventDate(document.eventDate);
      setRSVP(document.rsvp);
      setEventMonth(document.month);
      setType("Update");
    }
  }, [document]);

  const handleFileChange = (e) => {
    setThumbnail(null);
    let selected = e.target.files[0];
    console.log(selected);

    if (!selected) {
      setThumbnailError("Please select a file");
      return;
    }
    if (!selected.type.includes("image")) {
      setThumbnailError("Selected file must be an image");
      return;
    }

    setThumbnailError(null);
    setThumbnail(selected);
    console.log(thumbnail);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (eventMonth !== "") {
      if (eventName !== "") {
        if (eventDate !== "") {
          if (eventTime !== "") {
            if (eventDescription !== "") {
              if ((thumbnail !== null) | (id !== null)) {
                let imgUrl = "";
                setLoading(true);
                if (thumbnail !== null) {
                  const uploadPath = `events/${thumbnail.name}`;
                  const img = await projectStorage
                    .ref(uploadPath)
                    .put(thumbnail);
                  imgUrl = await img.ref.getDownloadURL();
                }

                let event = {};

                if (type === "Create") {
                  event = {
                    eventName,
                    eventDate,
                    eventTime,
                    eventDescription,
                    rsvp,
                    month: eventMonth,
                    rsvps: [],
                    eventURL: imgUrl,
                  };

                  await addDocument(event);
                  if (!response.error) {
                    setLoading(false);
                  }
                } else {
                  if (thumbnail === null) {
                    imgUrl = document.eventURL;
                  }

                  event = {
                    eventName,
                    eventDate,
                    eventTime,
                    eventDescription,
                    month: eventMonth,
                    rsvp,
                    eventURL: imgUrl,
                  };

                  await updateDocument(id, event);
                  if (!response.error) {
                    setLoading(false);
                  }
                }

                if (!response.error) {
                  history.push("/dashboard");
                }
              } else {
                setErrorLabel("ERROR: Please upload a photo!");
              }
            } else {
              setErrorLabel("ERROR: Please enter event description!");
            }
          } else {
            setErrorLabel("ERROR: Please enter event time!");
          }
        } else {
          setErrorLabel("ERROR: Please add event date!");
        }
      } else {
        setErrorLabel("ERROR: Please add event name!");
      }
    } else {
      setErrorLabel("ERROR: Please select event month");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Event Details</h4>
        <p>All fields are required</p>
        <form>
          <div className="form-group">
            <label className="form-label mt-4">Event Name</label>
            <input
              type="text"
              className="form-control"
              id="eventName"
              placeholder="Event Name (Required)"
              onChange={(e) => setEventName(e.target.value)}
              value={eventName}
            />
          </div>
          <div className="form-group">
            <label className="form-label mt-4">Event Month</label>
            <select
              class="form-select"
              id="exampleSelect1"
              onChange={(e) => setEventMonth(e.target.value)}
            >
              <option></option>
              <option>January</option>
              <option>Feburary</option>
              <option>March</option>
              <option>April</option>
              <option>May</option>
              <option>June</option>
              <option>July</option>
              <option>August</option>
              <option>September</option>
              <option>October</option>
              <option>November</option>
              <option>December</option>
            </select>
          </div>
          <div className="form-group">
            <label className="form-label mt-4">Event Date</label>
            <input
              type="text"
              className="form-control"
              id="eventDate"
              placeholder="Date (Required - MM/DD/YYYY)"
              onChange={(e) => setEventDate(e.target.value)}
              value={eventDate}
            />
          </div>
          <div className="form-group">
            <label className="form-label mt-4">Event Time</label>
            <input
              type="text"
              className="form-control"
              id="eventTime"
              placeholder="Event Time (Required)"
              onChange={(e) => setEventTime(e.target.value)}
              value={eventTime}
            />
            <div className="form-group">
              <label className="form-label mt-4">Event Description</label>
              <input
                type="text"
                className="form-control"
                id="description"
                placeholder="Event Description (Required)"
                value={eventDescription}
                onChange={(e) => setEventDescription(e.target.value)}
              />
            </div>
            <fieldset
              className="form-group"
              onChange={(e) => setRSVP(e.target.value)}
            >
              <legend className="mt-4">Select RSVP Requirement:</legend>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios"
                  id="optionsRadios1"
                  value={"none"}
                  checked={rsvp === "none"}
                />
                <label className="form-check-label">No RSVP Required</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios"
                  id="optionsRadios2"
                  value={"one"}
                  checked={rsvp === "one"}
                />
                <label className="form-check-label">RSVP Required</label>
              </div>
              {/* <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios"
                  id="optionsRadios3"
                  value={"count"}
                  checked={rsvp === "count"}
                />
                <label className="form-check-label">
                  RSVP and Headcount Required
                </label>
              </div> */}
            </fieldset>
          </div>
        </form>
        <label>
          <span>Event Image: </span>
          <input required type="file" onChange={handleFileChange} />
          {thumbnailError && <div className="error">{thumbnailError}</div>}
        </label>

        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <ImSpinner2
              icon="spinner"
              className="spinner"
              style={{
                marginRight: "5px",
              }}
            />
          )}
          {loading && <span>...saving</span>}
          {!loading && <span>{type}</span>}
        </button>
        <label>{errorLabel}</label>
      </div>
    </div>
  );
}

export default EventForm;
