import React from "react";
import { useCollection } from "../../hooks/useCollection";
import SponsorTableRow from "./SponsorTableRow";

const SponsorInqTable = () => {
  const { isPending, error, documents } = useCollection("sponsorSubmit");

  return (
    <div className="user-list">
      <h2>Sponsor Inquiries</h2>
      {isPending && <div>Loading Inquiries from Companies...</div>}
      {error && <div>{error}</div>}
      {documents && (
        <table className="table table-hover">
          <thead>
            <tr className="table-dark">
              <th scope="col">Company Name</th>
              <th scope="col">Contact</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {documents.map((sponsor) => (
            <tbody key={sponsor.id} className="user-list-item">
              <SponsorTableRow
                companyName={sponsor.companyName}
                contact={sponsor.contactName}
                email={sponsor.email}
                phone={sponsor.phoneNumber}
                id={sponsor.id}
                type={true}
              />
            </tbody>
          ))}
        </table>
      )}
    </div>
  );
};

export default SponsorInqTable;
