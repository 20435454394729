import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import SignIn from "./screens/SignIn/SignIn";
import Membership from "./screens/Membership";
import Events from "./screens/Events";
import Sponsors from "./screens/Sponsors";
import Board from "./screens/Board/Board";
import Contact from "./screens/Contact/Contact";
import Dashboard from "./screens/Dashboard";
import SponsorSignUp from "./screens/SponsorSignUp";
import { useAuthContext } from "./hooks/useAuthContext";
import CreateAdmin from "./screens/CreateAdmin";
import EventForm from "./components/EventForm";
import ProductForm from "./components/ProductForm";
import UserProfileForm from "./components/UserProfileForm";
import Store from "./screens/Store/Store";
import BecomeSponsor from "./screens/BecomeSponsor/BecomeSponsor";
import ViewRSVP from "./screens/ViewRSVP";
import SuggestEvent from "./components/SuggestEvent";
import NewSponsor from "./screens/BecomeSponsor/NewSponsor";
import EditPhoto from "./components/EditPhoto";
import LoggedOutEvents from "./screens/LoggedOutEvents";
import ProfilePicture from "./components/UserDashboard/ProfilePicture";
import NotificationForm from "./components/NotificationForm";
import PostPayment from "./screens/PostPayment";
import Training from "./screens/Training/Training";

function App() {
  const { user } = useAuthContext();
  return (
    <Router>
      <Header />
      <Container>
        <Route path="/login" component={SignIn} />
        <Route path="/membership" component={Membership} />
        <Route path="/sponsors" component={Sponsors} />
        <Route path="/" component={Home} exact />
        <Route path="/board" component={Board} />
        <Route path="/contact" component={Contact} />
        <Route path="/store" component={Store} />
        <Route path="/events" component={Events} />
        <Route path="/upcomingEvents" component={LoggedOutEvents} />
        {user && <Route path="/dashboard" component={Dashboard} />}
        <Route path="/sponsorSignUp" component={SponsorSignUp} />
        <Route path="/createAdmin" component={CreateAdmin} />
        <Route path="/eventForm" component={EventForm} />
        <Route path="/productForm" component={ProductForm} />
        <Route path="/editProfile" component={UserProfileForm} />
        <Route path="/becomeSponsor" component={BecomeSponsor} />
        <Route path="/eventRSVP/:id" component={ViewRSVP} />
        <Route path="/editEvent/:id" component={EventForm} />
        <Route path="/editProduct/:id" component={ProductForm} />
        <Route path="/editSponsor/:id" component={SponsorSignUp} />
        <Route path="/suggestEvent" component={SuggestEvent} />
        <Route path="/submitInfo" component={NewSponsor} />
        <Route path="/editPhoto/:id" component={EditPhoto} />
        <Route path="/profilePicture/:uid" component={ProfilePicture} />
        <Route path="/notificationForm" component={NotificationForm} />
        <Route path="/training" component={Training} />
        <Route
          path="/postpayment/:status/:userUID/:type"
          component={PostPayment}
        />
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
