import React from "react";
import { useHistory } from "react-router";
import { useCollection } from "../../hooks/useCollection";
import ProductTableRow from "./ProductTableRow";

const ProductTable = () => {
  const { isPending, error, documents } = useCollection("store");
  const history = useHistory();
  return (
    <div className="user-list">
      <h2>All Products</h2>
      <button
        type="submit"
        className="btn btn-success"
        style={{ marginBottom: "15px" }}
        onClick={() => history.push("/productForm")}
      >
        Add Product
      </button>
      {isPending && <div>Loading Products...</div>}
      {error && <div>{error}</div>}
      {documents && (
        <table className="table table-hover">
          <thead>
            <tr className="table-dark">
              <th scope="col">Product Name</th>
              <th scope="col">Description</th>
              <th scope="col">Price</th>
              <th scope="col">Qty</th>
              <th scope="col">Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {documents.map((product) => (
            <tbody key={product.id} className="user-list-item">
              <ProductTableRow
                productName={product.productName}
                description={product.productDescription}
                price={product.productPrice}
                qty={product.productQty}
                status={product.productStatus}
                id={product.id}
              />
            </tbody>
          ))}
        </table>
      )}
    </div>
  );
};

export default ProductTable;
