import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirestore } from "../hooks/useFirestore";
import "./Membership/memberForm.css";
import { ImSpinner2 } from "react-icons/im";

function NotificationForm() {
  const history = useHistory();

  const { addDocument, response } = useFirestore("notifications");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState("alert-primary");
  const [errorLabel, setErrorLabel] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title !== "") {
      if (description !== "") {
        setLoading(true);
        let notification = {};

        notification = {
          title,
          description,
          color,
        };

        await addDocument(notification);

        if (!response.error) {
          setLoading(false);
          history.push("/dashboard");
        }
      } else {
        setErrorLabel("ERROR: Please add title!");
      }
    } else {
      setErrorLabel("ERROR: Please add description!");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Event Details</h4>
        <p>All fields are required</p>
        <form>
          <div className="form-group">
            <label className="form-label mt-4">Notification Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Title (Required)"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div className="form-group">
            <label className="form-label mt-4">Notification Description</label>
            <input
              type="text"
              className="form-control"
              id="description"
              placeholder="Description (Required)"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>

          <fieldset
            className="form-group"
            onChange={(e) => setColor(e.target.value)}
          >
            <legend className="mt-4">Select Color:</legend>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="optionsRadios"
                id="optionsRadios1"
                value={"alert-primary"}
                checked={color === "alert-primary"}
              />
              <label className="form-check-label">Blue</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="optionsRadios"
                id="optionsRadios2"
                value={"alert-danger"}
                checked={color === "alert-danger"}
              />
              <label className="form-check-label">Red</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="optionsRadios"
                id="optionsRadios3"
                value={"alert-warning"}
                checked={color === "alert-warning"}
              />
              <label className="form-check-label">Orange</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="optionsRadios"
                id="optionsRadios3"
                value={"alert-success"}
                checked={color === "alert-success"}
              />
              <label className="form-check-label">Green</label>
            </div>
          </fieldset>
        </form>

        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <ImSpinner2
              icon="spinner"
              className="spinner"
              style={{
                marginRight: "5px",
              }}
            />
          )}
          {loading && <span>...saving</span>}
          {!loading && <span>Submit</span>}
        </button>
        <label>{errorLabel}</label>
      </div>
    </div>
  );
}

export default NotificationForm;
