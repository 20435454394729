import React from "react";
import { useFirestore } from "../../hooks/useFirestore";

const AdminTableRow = ({ firstName, lastName, email, position, id, user }) => {
  const { updateDocument } = useFirestore("users");

  const removeAdmin = () => {
    const update = {
      board: false,
    };

    console.log(id);
    updateDocument(id, update);
  };

  return (
    <tr className="table-light">
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{email}</td>
      <td>{position}</td>
      <td>
        <button type="submit" className="btn btn-primary" onClick={removeAdmin}>
          Remove
        </button>
      </td>
    </tr>
  );
};

export default AdminTableRow;
