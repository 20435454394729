import React from "react";

const BoardCard = ({
  position,
  firstName,
  lastName,
  email,
  bio,
  phoneNumber,
  image,
}) => {
  console.log(image);
  return (
    <div
      className="card border-primary mb-3"
      style={{ maxWidth: "20rem", margin: "20px" }}
    >
      <div className="card-header">{position}</div>
      <div className="card-body">
        <img
          alt="profile"
          src={image}
          style={{
            objectFit: "scale-down",
            height: "250px",
            maxWidth: "100%",
          }}
          className="d-block user-select-none card-body"
        />

        <h4 className="card-title">
          {firstName} {lastName}
        </h4>
        <a href={`tel:+1-${phoneNumber}`}>{phoneNumber}</a>
        <p className="card-text">{bio}</p>
        <a className="card-text" href="mailto:max.mustermann@example.com">
          {email}
        </a>
      </div>
    </div>
  );
};

export default BoardCard;
