import { Component, React } from "react";
import Description from "../components/Descriptions";
import MemberForm from "../components/Membership/MemberForm";

class Membership extends Component {
  state = {
    visible: true,
  };

  render() {
    return (
      <div>
        {this.state.visible ? (
          <>
            <Description />
            <button
              type="submit"
              className="btn btn-danger"
              onClick={() => {
                this.setState({ visible: false });
              }}
            >
              Join Now!
            </button>
          </>
        ) : (
          <>
            <MemberForm />
            <button
              type="submit"
              className="btn btn-warning"
              onClick={() => {
                this.setState({ visible: true });
              }}
            >
              Go Back
            </button>
          </>
        )}
      </div>
    );
  }
}

export default Membership;
