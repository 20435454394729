import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./filter.css";

function FilterTest({ changeFilter }) {
  const [currentFilter, setCurrentFilter] = useState("All");

  const filterList = [
    "All",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleClick = (newFilter) => {
    setCurrentFilter(newFilter);
    changeFilter(newFilter);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="warning"
      variant="dark"
      style={{ marginTop: "20px", maxWidth: "100%" }}
    >
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {filterList.map((f) => (
            <Nav.Link
              key={f}
              onClick={() => handleClick(f)}
              style={{ margin: "8px", color: "white" }}
              className={currentFilter === f ? "active" : ""}
            >
              {f}
            </Nav.Link>
          ))}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default FilterTest;
