import React, { useState } from "react";
import "../../components/Membership/memberForm.css";
import { useFirestore } from "../../hooks/useFirestore";
import { useHistory } from "react-router-dom";

function SponsorSignUp() {
  const history = useHistory();
  const { addDocument, response } = useFirestore("sponsorSubmit");

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [errorLabel, setErrorLabel] = useState("");
  const [website, setWebsite] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email !== "") {
      if (phoneNumber !== "") {
        if (contactName !== "") {
          if (companyName !== "") {
            if (website !== "") {
              const sponsor = {
                email,
                phoneNumber,
                contactName,
                companyName,
                website,
              };

              await addDocument(sponsor);

              if (!response.error) {
                history.push("/");
              }
            } else {
              setErrorLabel("ERROR: Please enter a company website!");
            }
          } else {
            setErrorLabel("ERROR: Please enter a company name!");
          }
        } else {
          setErrorLabel("ERROR: Please enter a contact name!");
        }
      } else {
        setErrorLabel("ERROR: Please enter phone number!");
      }
    } else {
      setErrorLabel("ERROR: Please enter email address!");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Add New Sponsor</h4>
        <p>All Fields Required</p>
        <form>
          <fieldset>
            <div className="form-group">
              <label className="form-label mt-4">Company Name</label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Contact At Company</label>
              <input
                type="text"
                className="form-control"
                id="contactName"
                placeholder="Contact Name"
                onChange={(e) => setContactName(e.target.value)}
                value={contactName}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                placeholder="Phone Number"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Website Link</label>
              <input
                type="text"
                className="form-control"
                id="website"
                placeholder="Website Link"
                onChange={(e) => setWebsite(e.target.value)}
                value={website}
              />
            </div>
            <button
              type="submit"
              className="btn btn-success"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <label>{errorLabel}</label>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default SponsorSignUp;
