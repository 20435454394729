import { useState, useEffect } from "react";
import { projectAuth, projectFirestore } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async (
    email,
    password,
    firstName,
    lastName,
    membership,
    gender,
    military,
    branch,
    race,
    identity,
    relation,
    phoneNumber,
    address,
    city,
    state,
    zipCode,
    memberId
  ) => {
    setError(null);
    setIsPending(true);

    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(
        email,
        password
      );

      if (!res) {
        throw new Error("Could not complete signup");
      }

      // add display name to user
      await res.user.updateProfile({ firstName });

      // create a user document
      await projectFirestore.collection("users").doc(res.user.uid).set({
        admin: false,
        bio: "",
        board: false,
        dues: "unpaid",
        duesDue: "",
        duesLastPaid: "",
        email: email,
        uid: res.user.uid,
        firstName: firstName,
        lastName: lastName,
        city: city,
        zipCode: zipCode,
        address: address,
        state: state,
        phoneNumber: phoneNumber,
        gender,
        military,
        branch,
        race,
        identity,
        relation,
        job: "",
        jobFunction: "",
        jobStatus: "",
        pictureURL: "",
        membership: membership,
        rsvps: [],
        memberId: memberId,
      });

      // dispatch login action
      dispatch({ type: "LOGIN", payload: res.user });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { signup, error, isPending };
};
