import React from "react";
import { useHistory } from "react-router";
import { useCollection } from "../../hooks/useCollection";
import EventTableRow from "./EventTableRow";

const EventTable = () => {
  const { isPending, error, documents } = useCollection("events");
  const history = useHistory();
  return (
    <div className="user-list">
      <h2>All Events</h2>
      <button
        type="submit"
        className="btn btn-success"
        style={{ marginBottom: "15px" }}
        onClick={() => history.push("/eventForm")}
      >
        Add Event
      </button>
      {isPending && <div>Loading Events...</div>}
      {error && <div>{error}</div>}
      {documents && (
        <table className="table table-hover">
          <thead>
            <tr className="table-dark">
              <th scope="col">Event Name</th>
              <th scope="col">Description</th>
              <th scope="col">Time</th>
              <th scope="col">Date</th>
              <th scope="col">RSVP Type</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {documents.map((event) => (
            <tbody key={event.id} className="user-list-item">
              <EventTableRow
                eventName={event.eventName}
                eventDescription={event.eventDescription}
                eventDate={event.eventDate}
                eventTime={event.eventTime}
                rsvp={event.rsvp}
                id={event.id}
                type={true}
              />
            </tbody>
          ))}
        </table>
      )}
    </div>
  );
};

export default EventTable;
