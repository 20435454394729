import React, { useEffect, useState } from "react";

const HelpRequestForm = ({ document, onApprove, onReject }) => {
  const [createdDate, setCreatedDate] = useState([]);

  useEffect(() => {
    if (document) {
      const createdAt = new Date(
        document.createdAt.seconds * 1000 +
          document.createdAt.nanoseconds / 1000000
      );

      setCreatedDate(createdAt.toString());
    }
  }, [createdDate, document]);

  return (
    <div style={{ backgroundColor: "wheat" }}>
      <p>Form Submitted On: {createdDate}</p>
      <p>Amount Requested: {document.amountRequested}</p>
      <p>Emergency: {document.emergency}</p>
      {document.emergency === "Yes" && <p>{document.explaination}</p>}
      <p>Current Situation: {document.reasoning}</p>
      <p>Previous Asistancce: {document.previousAssistance}</p>
      <h5 style={{ paddingLeft: "8px", textDecoration: "underline" }}>
        Personal Info
      </h5>
      <p>
        {document.legalName} ({document.pronouns})
      </p>
      <p>Preferred Name: {document.preferredName}</p>
      <p>DOB: {document.dob}</p>
      <p>LGBTQ+ Status: {document.lgbt}</p>
      <p>Employement Status: {document.employeed}</p>
      {document.jobLocation !== "" && (
        <>
          <p>Employeer: {document.jobLocation}</p>
          <p>Safe Place Initiative: {document.jobSafe}</p>
        </>
      )}
      <h5 style={{ paddingLeft: "8px", textDecoration: "underline" }}>
        Contact Info
      </h5>
      <p>{document.email}</p>
      <p>{document.phoneNumber}</p>
      <div style={{ paddingBottom: "12px" }}>
        <button
          type="submit"
          className="btn btn-success"
          onClick={onApprove}
          style={{ marginLeft: "18px", marginRight: "18px" }}
        >
          Approve
        </button>
        <button type="submit" className="btn btn-danger" onClick={onReject}>
          Reject
        </button>
      </div>
    </div>
  );
};

export default HelpRequestForm;
