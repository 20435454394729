import React from "react";
import { Row } from "react-bootstrap";

const Second = () => {
  return (
    <div className="grid-container">
      <div className="card mb-3 mt-3" style={{ maxWidth: "30rem" }}>
        <div className="card-header" style={{ fontSize: "20px" }}>
          Educate LGBTQ and non-LGBTQ criminal justice professionals and the
          community in which we serve
          <Row style={{ fontSize: "20px", paddingLeft: "10px" }}></Row>
        </div>
        <div className="card-body">
          <p className="card-text">
            <ul>
              <li>
                Provide sensitivity training to law enforcement agencies and
                local businesses
              </li>
              <li>
                Provide training to new recruits at police academies and law
                enforcement agencies across Central Florida
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Second;
