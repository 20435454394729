import React, { useEffect, useState } from "react";
import "../components/Membership/memberForm.css";
import { useFirestore } from "../hooks/useFirestore";
import { useHistory } from "react-router-dom";
import { projectStorage } from "../firebase/config";
import { useDocument } from "../hooks/useDocument";
import { useParams } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";

function SponsorSignUp() {
  const history = useHistory();
  const { id } = useParams();
  const { document } = useDocument("sponsors", id);
  const { addDocument, updateDocument, response } = useFirestore("sponsors");
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [errorLabel, setErrorLabel] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);
  const [website, setWebsite] = useState("");
  const [membershipLevel, setMembershipLevel] = useState("");
  const [type, setType] = useState("Add");

  useEffect(() => {
    if (document) {
      console.log(document);
      setEmail(document.email);
      setPhoneNumber(document.phoneNumber);
      setContactName(document.contactName);
      setCompanyName(document.companyName);
      setWebsite(document.website);
      setType("Update");
      setMembershipLevel(document.type);
    }
  }, [document]);
  console.log(membershipLevel);

  const handleFileChange = (e) => {
    setThumbnail(null);
    let selected = e.target.files[0];
    console.log(selected);

    if (!selected) {
      setThumbnailError("Please select a file");
      return;
    }
    if (!selected.type.includes("image")) {
      setThumbnailError("Selected file must be an image");
      return;
    }

    setThumbnailError(null);
    setThumbnail(selected);
    console.log("thumbnail updated");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (membershipLevel !== "") {
      if (email !== "") {
        if (phoneNumber !== "") {
          if (contactName !== "") {
            if (companyName !== "") {
              if (website !== "") {
                setLoading(true);
                if ((thumbnail !== null) | (id !== null)) {
                  let imgUrl = "";
                  if (thumbnail !== null) {
                    // upload user thumbnail
                    const uploadPath = `thumbnails/sponsors/${thumbnail.name}`;
                    const img = await projectStorage
                      .ref(uploadPath)
                      .put(thumbnail);
                    imgUrl = await img.ref.getDownloadURL();
                  }

                  let sponsor = {};

                  let sortLevel = 0;

                  if (membershipLevel === "Platinum") {
                    sortLevel = 1;
                  }

                  if (membershipLevel === "Silver") {
                    sortLevel = 2;
                  }

                  if (membershipLevel === "Gold") {
                    sortLevel = 3;
                  }

                  if (membershipLevel === "Bronze") {
                    sortLevel = 4;
                  }

                  if (website.substring(0, 1) !== "h") {
                    setLoading(false);
                    console.log("ETES");
                    setWebsite("http://" + website);
                  }

                  console.log(website);

                  if (type === "Add") {
                    sponsor = {
                      email,
                      phoneNumber,
                      contactName,
                      companyName,
                      website,
                      type: membershipLevel,
                      sort: sortLevel,
                      logoURL: imgUrl,
                    };

                    await addDocument(sponsor);
                  } else {
                    if (thumbnail === null) {
                      imgUrl = document.logoURL;
                    }

                    if (website.substring(0, 1) !== "h") {
                      console.log("ETES");
                      setWebsite("http://" + website);
                    }

                    sponsor = {
                      email,
                      phoneNumber,
                      contactName,
                      companyName,
                      type: membershipLevel,
                      sort: sortLevel,
                      website,
                      logoURL: imgUrl,
                    };

                    console.log(sponsor);
                    await updateDocument(id, sponsor);
                    if (!response.error) {
                      setLoading(false);
                    }
                  }
                  if (!response.error) {
                    history.push("/dashboard");
                  }
                } else {
                  setErrorLabel("ERROR: Please upload company logo!");
                }
              } else {
                setErrorLabel("ERROR: Please enter a company website!");
              }
            } else {
              setErrorLabel("ERROR: Please enter a company name!");
            }
          } else {
            setErrorLabel("ERROR: Please enter a contact name!");
          }
        } else {
          setErrorLabel("ERROR: Please enter phone number!");
        }
      } else {
        setErrorLabel("ERROR: Please enter email address!");
      }
    } else {
      setErrorLabel("ERROR: Please select membership level");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Add New Sponsor</h4>
        <p>All Fields Required</p>
        <form>
          <fieldset>
            <div className="form-group">
              <label className="form-label mt-4">Company Name</label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Contact At Company</label>
              <input
                type="text"
                className="form-control"
                id="contactName"
                placeholder="Contact Name"
                onChange={(e) => setContactName(e.target.value)}
                value={contactName}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                placeholder="Phone Number"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Website Link</label>
              <input
                type="text"
                className="form-control"
                id="website"
                placeholder="Website Link"
                onChange={(e) => setWebsite(e.target.value)}
                value={website}
              />
            </div>
            <fieldset
              className="form-group"
              onChange={(e) => setMembershipLevel(e.target.value)}
            >
              <legend className="mt-4">Select Membership Type:</legend>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios1"
                  id="optionsRadios1"
                  value="Platinum"
                  checked={membershipLevel === "Platinum" ? true : false}
                  onChange={() => setMembershipLevel("Platinum")}
                />
                <label className="form-check-label">Platinum</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios2"
                  id="optionsRadios2"
                  value="Gold"
                  checked={membershipLevel === "Gold" ? true : false}
                  onChange={() => setMembershipLevel("Gold")}
                />
                <label className="form-check-label">Gold</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios3"
                  id="optionsRadios3"
                  value="Silver"
                  checked={membershipLevel === "Silver" ? true : false}
                  onChange={() => setMembershipLevel("Silver")}
                />
                <label className="form-check-label">Silver</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios4"
                  id="optionsRadios4"
                  value="Bronze"
                  checked={membershipLevel === "Bronze" ? true : false}
                  onChange={() => setMembershipLevel("Bronze")}
                />
                <label className="form-check-label">Bronze</label>
              </div>
            </fieldset>

            <label>
              <span>Profile thumbnail:</span>
              <input required type="file" onChange={handleFileChange} />
              {thumbnailError && <div className="error">{thumbnailError}</div>}
            </label>
            <button
              type="submit"
              className="btn btn-success"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading && (
                <ImSpinner2
                  icon="spinner"
                  className="spinner"
                  style={{
                    marginRight: "5px",
                  }}
                />
              )}
              {loading && <span>Processing Selection</span>}
              {!loading && <span>{type}</span>}
            </button>
            <label>{errorLabel}</label>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default SponsorSignUp;
