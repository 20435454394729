import React from "react";
import { useCollection } from "../../hooks/useCollection";

const MemberStats = () => {
  const { documents } = useCollection("memberInfo");

  return (
    <div>
      <h2>Member Stats</h2>
      {documents && (
        <>
          <h5>Member Totals</h5>
          <p>Total Member Count: {documents[0].currentMemberCount - 200}</p>
          <p>Total Associate Members: {documents[0].assoc}</p>
          <p>Total Professional Members: {documents[0].profess}</p>
          <h5>Ethnicity</h5>
          <p>African American: {documents[0].african}</p>
          <p>Asian: {documents[0].asian}</p>
          <p>Caucasian: {documents[0].caucasian}</p>
          <p>Hispanic: {documents[0].hispanic}</p>
          <p>Other: {documents[0].raceOther}</p>
          <p>Prefer not to say: {documents[0].racePerf}</p>
          <h5>Gender</h5>
          <p>Male: {documents[0].male}</p>
          <p>Female: {documents[0].female}</p>
          <p>Other: {documents[0].genOther}</p>
          <p>Prefer not to say: {documents[0].genPerfer}</p>
          <h5>Identity</h5>
          <p>Bi: {documents[0].bi}</p>
          <p>Gay: {documents[0].gay}</p>
          <p>Lesbian: {documents[0].lesbian}</p>
          <p>straight: {documents[0].straight}</p>
          <p>Transgender: {documents[0].trans}</p>
          <p>Non-Binary: {documents[0].nonBinary}</p>
          <p>Queer: {documents[0].queer}</p>
          <p>Other: {documents[0].idOther}</p>
          <p>Prefer not to say: {documents[0].idPerfer}</p>
          <h5>Military</h5>
          <p>Active Military: {documents[0].activeMilitary}</p>
          <p>Retired Military: {documents[0].retiredMilitary}</p>
        </>
      )}
    </div>
  );
};

export default MemberStats;
