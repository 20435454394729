import React from "react";
import { useCollection } from "../hooks/useCollection";
import SponsorCard from "../components/SponsorCard";

const Sponsors = () => {
  const { isPending, error, documents } = useCollection("sponsors");

  let sorted = [];

  if (documents) {
    sorted = documents.sort((a, b) => (a.sort > b.sort ? 1 : -1));
  }

  return (
    <div>
      <h2 style={{ marginTop: "20px" }}>
        A Special THANK YOU for your support!!!
      </h2>
      <h4 style={{ marginBottom: "40px" }}>
        Please spend a few minutes on their websites and see what they have to
        offer
      </h4>
      {documents &&
        sorted.map((sponsor) => (
          <div style={{ marginTop: "15px" }}>
            <SponsorCard website={sponsor.website} logoURL={sponsor.logoURL} />
          </div>
        ))}
    </div>
  );
};
//TODO the local bar and grill (and something else) has no website. need to do something with its link
export default Sponsors;
