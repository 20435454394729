import React, { useState } from "react";
import LoggedOutEventCard from "../components/LoggedOutEventCard";
import { useCollection } from "../hooks/useCollection";
import Filter from "../components/Filter/Filter";

const LoggedOutEvents = () => {
  const { documents } = useCollection("events");
  console.log(documents);
  const [filter, setFilter] = useState("All");
  let sorted = [];

  if (documents) {
    sorted = documents.sort((a, b) => (a.eventDate > b.eventDate ? 1 : -1));
  }

  const changeFilter = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <div className="container">
      <Filter changeFilter={changeFilter} />
      <h1
        style={{ marginTop: "8px", marginBottom: "-5px", textAlign: "center" }}
      >
        {filter === "All" ? "All Events" : `Events for ${filter}`}
      </h1>
      {documents &&
        sorted.map((event) =>
          filter !== "All" ? (
            event.month === filter && (
              <LoggedOutEventCard
                className="card"
                key={event.id}
                eventDate={event.eventDate}
                eventDescription={event.eventDescription}
                eventName={event.eventName}
                eventTime={event.eventTime}
                eventURL={event.eventURL}
                rsvp={event.rsvp}
                rsvps={event.rsvps}
                id={event.id}
              />
            )
          ) : (
            <LoggedOutEventCard
              className="card"
              key={event.id}
              eventDate={event.eventDate}
              eventDescription={event.eventDescription}
              eventName={event.eventName}
              eventTime={event.eventTime}
              eventURL={event.eventURL}
              rsvp={event.rsvp}
              rsvps={event.rsvps}
              id={event.id}
            />
          )
        )}
    </div>
  );
};

export default LoggedOutEvents;
