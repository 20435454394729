import { Link } from "react-router-dom";
import styled from "styled-components";

import { btnReset, v } from "./variables";

export const SSidebar = styled.div`
  width: ${({ isOpen }) => (!isOpen ? `90px` : v.sidebarWidth)};
  background: rgb(99, 172, 200);
  height: 150vh;
  padding: ${v.lgSpacing};
  margin-left: -30px;
  margin-right: 25px;
  position: relative;
`;

export const SSidebarButton = styled.button`
  ${btnReset};
  position: absolute;
  top: 8px;
  left: 30px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgb(240, 240, 240);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transform: ${({ isOpen }) => (!isOpen ? `rotate(180deg)` : `initial`)};
`;

export const SDivider = styled.div`
  height: 1px;
  width: 100%;
  background: rgb(240, 240, 240);
  margin: ${v.lgSpacing} 0;
`;

export const SLinkContainer = styled.div`
  background: ${({ theme, isActive }) =>
    !isActive ? `transparent` : theme.bg3};
  border-radius: ${v.borderRadius};
  margin: 8px 0;

  :hover {
    box-shadow: inset 0 0 0 1px rgb(22, 140, 186);
    background: rgb(240, 240, 240);
  }
`;

export const SLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(240, 240, 240);
  font-size: 16px;
  padding: calc(${v.smSpacing} - 2px) 0;
`;

export const SLinkIcon = styled.div`
  padding: ${v.smSpacing} ${v.mdSpacing};
  display: flex;

  svg {
    font-size: 20px;
  }
`;

export const SLinkLabel = styled.span`
  display: block;
  flex: 1;
  margin-left: ${v.smSpacing};
`;

export const SLinkNotification = styled.div`
  font-size: 14px;
  padding: calc(${v.smSpacing} / 2) ${v.smSpacing};
  border-radius: calc(${v.borderRadius} / 2);
  background: rgb(22, 140, 186);
  color: rgb(240, 240, 240);

  margin-right: ${v.mdSpacing};
`;

export const STheme = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;
export const SThemeLabel = styled.span`
  display: block;
  flex: 1;
`;
export const SThemeToggler = styled.button`
  ${btnReset};
  margin: 0 auto;
  cursor: pointer;
  width: 36px;
  height: 20px;
  border-radius: 10px;
  background: rgb(22, 140, 186);

  position: relative;
`;

export const SToggleThumb = styled.div`
  height: 18px;
  width: 18px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  transition: 0.2s ease right;
  right: calc(100% - 18px - 1px);
  border-radius: 50%;
  background: rgb(22, 140, 186);
`;
