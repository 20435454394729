import React from "react";
import { useCollection } from "../../hooks/useCollection";
import NotificationBanner from "./NotificationBanner";

const Notifications = ({ userUID, showButton }) => {
  const { isPending, error, documents } = useCollection("notifications");

  console.log(documents);
  return (
    <div className="user-list" style={{ marginTop: "25px" }}>
      <h3>Your Notifications</h3>
      {isPending && <div>Loading Notifications...</div>}
      {error && <div>No Notifications</div>}
      {documents &&
        documents.map((document) => (
          <NotificationBanner
            id={document.id}
            title={document.title}
            description={document.description}
            color={document.color}
            showButton={showButton}
          />
        ))}
      {documents && documents.length === 0 && (
        <div>There are currently no notifications</div>
      )}
    </div>
  );
};

export default Notifications;
