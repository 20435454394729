import React, { useEffect, useState } from "react";
import { useCollection } from "../hooks/useCollection";
import { useParams } from "react-router-dom";
import RSVPTableRow from "../components/RSVPTableRow";

const ViewRSVP = () => {
  const { id } = useParams();
  const { documents, error } = useCollection("RSVP");

  const [filteredRSVP, setFilterRSVP] = useState([]);

  useEffect(() => {
    if (documents) {
      const testing = documents.filter((event) => event.event === id);
      setFilterRSVP(testing);
    }
  }, [documents, id]);

  if (error) {
    return <div className="error">{error}</div>;
  }
  if (!document) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div>
      {filteredRSVP && (
        <>
          <h2>{filteredRSVP.eventName} RSVP List:</h2>
          <table className="table table-hover">
            <thead>
              <tr className="table-dark">
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            {filteredRSVP.map((rsvp) => (
              <tbody key={rsvp.id} className="user-list-item">
                <RSVPTableRow
                  firstName={rsvp.firstName}
                  lastName={rsvp.lastName}
                  email={rsvp.email}
                />
              </tbody>
            ))}
          </table>
        </>
      )}
    </div>
  );
};

export default ViewRSVP;
