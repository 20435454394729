import React, { useState, useEffect } from "react";
import "../Membership/memberForm.css";
import { useFirestore } from "../../hooks/useFirestore";
import { useDocument } from "../../hooks/useDocument";
import { useHistory } from "react-router-dom";
import { useSignup } from "../../hooks/useSignup";

function Settings({ userUID }) {
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bio, setBio] = useState("");
  const [errorPhotoLabel, setPhotoErrorLabel] = useState("");
  const [errorLabel, setErrorLabel] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pictureURL, setPictureURL] = useState("");
  const [boardMember, setBoardMember] = useState(false);
  const [gender, setGender] = useState("");
  const [identity, setIdentity] = useState("");
  const [race, setRace] = useState("");
  const [military, setMilitary] = useState("");
  const [branch, setBranch] = useState("");
  const [job, setJob] = useState("");
  const [jobFunction, setJobFunction] = useState("");
  const [jobStatus, setJobStatus] = useState("");

  const { document } = useDocument("users", userUID);

  const { updateDocument, response } = useFirestore("users");
  const { document: statsDocument } = useDocument(
    "memberInfo",
    "4oFlDJsmnbDGtPHbss26"
  );
  const { updateDocument: updateStats } = useFirestore("memberInfo");

  const history = useHistory();

  useEffect(() => {
    if (document) {
      console.log(document);
      setEmail(document.email);
      setAddress(document.address);
      setCity(document.city);
      setState(document.state);
      setFirstName(document.firstName);
      setLastName(document.lastName);
      setBio(document.bio);
      setPictureURL(document.pictureURL);
      setPhoneNumber(document.phoneNumber);

      setGender(document.gender);
      setIdentity(document.identity);
      setRace(document.race);
      setMilitary(document.military);
      setBranch(document.branch);
      setJob(document.job);
      setJobFunction(document.jobFunction);
      setJobStatus(document.jobStatus);

      if (document.board === true) {
        setBoardMember(true);
      }
    }
  }, [document]);

  const updatePhoto = () => {
    history.push(`/profilePicture/${userUID}`);
  };

  const phoneNumberStructure = (value) => {
    if (value.length === 3) {
      value = value + "-";
      console.log(value);
    }
    if (value.length === 7) {
      value = value + "-";
    }
    setPhoneNumber(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email !== "") {
      if (firstName !== "") {
        if (lastName !== "") {
          let user = {
            email,
            address,
            city,
            state,
            firstName,
            lastName,
            phoneNumber,
            bio,
            gender,
            identity,
            race,
            military,
            branch,
            job,
            jobFunction,
            jobStatus,
          };

          console.log(user);

          await updateDocument(userUID, user);
          if (!response.error) {
            setErrorLabel("Updates Saved!");
          }

          // update metrics
          // gender
          if (gender !== document.gender) {
            let changeFrom = {};

            switch (document.gender) {
              case "Male":
                changeFrom = {
                  male: statsDocument.male - 1,
                };
                break;
              case "Female":
                changeFrom = {
                  female: statsDocument.female - 1,
                };
                break;
              case "Perfer Not to Say":
                changeFrom = {
                  genPerfer: statsDocument.genPerfer - 1,
                };
                break;
              default:
                changeFrom = {
                  genOther: statsDocument.genOther - 1,
                };
            }

            await updateStats("4oFlDJsmnbDGtPHbss26", changeFrom);

            let changeTo = {};

            switch (gender) {
              case "Male":
                changeTo = {
                  male: statsDocument.male + 1,
                };
                break;
              case "Female":
                changeTo = {
                  female: statsDocument.female + 1,
                };
                break;
              case "Perfer Not to Say":
                changeTo = {
                  genPerfer: statsDocument.genPerfer + 1,
                };
                break;
              default:
                changeTo = {
                  genOther: statsDocument.genOther + 1,
                };
            }

            await updateStats("4oFlDJsmnbDGtPHbss26", changeTo);
          }

          // identity
          if (identity !== document.identity) {
            let changeFrom = {};

            switch (document.identity) {
              case "Bisexual":
                changeFrom = {
                  bi: statsDocument.bi - 1,
                };
                break;
              case "Gay":
                changeFrom = {
                  gay: statsDocument.gay - 1,
                };
                break;
              case "Lesbian":
                changeFrom = {
                  lesbian: statsDocument.lesbian - 1,
                };
                break;
              case "Non-binary":
                changeFrom = {
                  nonBinary: statsDocument.nonBinary - 1,
                };
                break;
              case "Straight":
                changeFrom = {
                  straight: statsDocument.straight - 1,
                };
                break;
              case "Transgender":
                changeFrom = {
                  trans: statsDocument.trans - 1,
                };
                break;
              case "Queer":
                changeFrom = {
                  queer: statsDocument.queer - 1,
                };
                break;
              case "Perfer Not to Say":
                changeFrom = {
                  idPerfer: statsDocument.idPerfer - 1,
                };
                break;
              default:
                changeFrom = {
                  idOther: statsDocument.idOther - 1,
                };
            }

            await updateStats("4oFlDJsmnbDGtPHbss26", changeFrom);

            let changeTo = {};

            switch (identity) {
              case "Bisexual":
                changeTo = {
                  bi: statsDocument.bi + 1,
                };
                break;
              case "Gay":
                changeTo = {
                  gay: statsDocument.gay + 1,
                };
                break;
              case "Lesbian":
                changeTo = {
                  lesbian: statsDocument.lesbian + 1,
                };
                break;
              case "Non-binary":
                changeTo = {
                  nonBinary: statsDocument.nonBinary + 1,
                };
                break;
              case "Straight":
                changeTo = {
                  straight: statsDocument.straight + 1,
                };
                break;
              case "Transgender":
                changeTo = {
                  trans: statsDocument.trans + 1,
                };
                break;
              case "Queer":
                changeTo = {
                  queer: statsDocument.queer + 1,
                };
                break;
              case "Perfer Not to Say":
                changeTo = {
                  idPerfer: statsDocument.idPerfer + 1,
                };
                break;
              default:
                changeTo = {
                  idOther: statsDocument.idOther + 1,
                };
            }

            await updateStats("4oFlDJsmnbDGtPHbss26", changeTo);
          }

          // race
          if (race !== document.race) {
            let changeFrom = {};

            switch (document.race) {
              case "African American":
                changeFrom = {
                  african: statsDocument.african - 1,
                };
                break;
              case "Asian":
                changeFrom = {
                  asian: statsDocument.asian - 1,
                };
                break;
              case "Caucasian":
                changeFrom = {
                  caucasian: statsDocument.caucasian - 1,
                };
                break;
              case "Hispanic":
                changeFrom = {
                  hispanic: statsDocument.hispanic - 1,
                };
                break;
              case "Perfer Not to Say":
                changeFrom = {
                  racePerf: statsDocument.racePerf - 1,
                };
                break;
              default:
                changeFrom = {
                  raceOther: statsDocument.raceOther - 1,
                };
            }

            await updateStats("4oFlDJsmnbDGtPHbss26", changeFrom);

            let changeTo = {};

            console.log(race);

            switch (race) {
              case "African American":
                changeTo = {
                  african: statsDocument.african + 1,
                };
                break;
              case "Asian":
                changeTo = {
                  asian: statsDocument.asian + 1,
                };
                break;
              case "Caucasian":
                changeTo = {
                  caucasian: statsDocument.caucasian + 1,
                };
                break;
              case "Hispanic":
                changeTo = {
                  hispanic: statsDocument.hispanic + 1,
                };
                break;
              case "Perfer Not to Say":
                changeTo = {
                  racePerf: statsDocument.racePerf + 1,
                };
                break;
              default:
                changeTo = {
                  raceOther: statsDocument.raceOther + 1,
                };
            }
            console.log(changeTo);
            await updateStats("4oFlDJsmnbDGtPHbss26", changeTo);
          }

          // military
          if (military !== document.military) {
            let changeFrom = {};

            switch (document.military) {
              case "Yes":
                changeFrom = {
                  retiredMilitary: statsDocument.retiredMilitary - 1,
                };
                break;
              case "No":
                break;
              default:
                changeFrom = {
                  activeMilitary: statsDocument.activeMilitary - 1,
                };
            }

            await updateStats("4oFlDJsmnbDGtPHbss26", changeFrom);

            let changeTo = {};
            switch (military) {
              case "Yes":
                changeTo = {
                  retiredMilitary: statsDocument.retiredMilitary + 1,
                };
                break;
              case "No":
                break;
              default:
                changeTo = {
                  activeMilitary: statsDocument.activeMilitary + 1,
                };
            }

            await updateStats("4oFlDJsmnbDGtPHbss26", changeTo);
          }
        } else {
          setErrorLabel("ERROR: Please enter your last name!");
        }
      } else {
        setErrorLabel("ERROR: Please enter your first name!");
      }
    } else {
      setErrorLabel("ERROR: Please enter a valid email address!");
    }
  };

  return (
    <>
      <div className="card memberForm">
        <div className="card-body">
          <form>
            <div style={{ display: "flex", marginTop: "30px" }}>
              <img
                src={pictureURL}
                alt="user-pic"
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50%",
                  marginRight: "25px",
                }}
              />
              <h4
                className="card-title"
                style={{ marginTop: "35px", marginRight: "90px" }}
              >
                Upload a New Photo
              </h4>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={updatePhoto}
                style={{ height: "50px", marginTop: "25px" }}
              >
                Update
              </button>
            </div>
            <label>{errorPhotoLabel}</label>
          </form>
        </div>
      </div>

      <div className="card memberForm">
        <div className="card-body">
          <h4 className="card-title">Update Your Information:</h4>
          <form>
            <fieldset>
              <div className="form-group">
                <label className="form-label mt-4">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name (Required)"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </div>
              <div className="form-group">
                <label className="form-label mt-4">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Last Name (Required)"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </div>
              <div className="form-group">
                <label className="form-label mt-4">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email (Required)"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <small>
                  This will not update login information! This will only update
                  the email that GoalCFL will contact you on.
                </small>
              </div>
              <div className="form-group">
                <label className="form-label mt-4">Phone Number</label>
                <input
                  type="phone"
                  className="form-control"
                  id="exampleInputAddress"
                  placeholder="Phone"
                  onChange={(e) => phoneNumberStructure(e.target.value)}
                  value={phoneNumber}
                />
              </div>
              <div className="form-group">
                <label className="form-label mt-4">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputAddress"
                  placeholder="Address"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </div>
              <div className="form-group">
                <label className="form-label mt-4">City</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputCity"
                  placeholder="City"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
              </div>
              <div className="form-group">
                <label className="form-label mt-4">State</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputState"
                  placeholder="State"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                />
              </div>
              {boardMember && (
                <div class="form-group">
                  <label for="exampleTextarea" class="form-label mt-4">
                    Board Member Bio
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleTextarea"
                    rows="3"
                    onChange={(e) => setBio(e.target.value)}
                    value={bio}
                  ></textarea>
                </div>
              )}

              <div className="form-group">
                <label className="form-label mt-4">Gender</label>
                <select
                  type="text"
                  className="form-control"
                  id="business"
                  placeholder="Your answer"
                  onChange={(e) => setGender(e.target.value)}
                  value={gender}
                >
                  <option>Pick One</option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Perfer Not to Say</option>
                  <option>Other</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label mt-4">How do you identify</label>
                <select
                  type="text"
                  className="form-control"
                  id="business"
                  placeholder="Your answer"
                  onChange={(e) => setIdentity(e.target.value)}
                  value={identity}
                >
                  <option>Pick One</option>
                  <option>Bisexual</option>
                  <option>Gay</option>
                  <option>Lesbian</option>
                  <option>Non-binary</option>
                  <option>Straight</option>
                  <option>Transgender</option>
                  <option>Queer</option>
                  <option>Perfer Not to Say</option>
                  <option>Other</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label mt-4">Race</label>
                <select
                  type="text"
                  className="form-control"
                  id="business"
                  placeholder="Your answer"
                  onChange={(e) => setRace(e.target.value)}
                  value={race}
                >
                  <option>Pick One</option>
                  <option>African American</option>
                  <option>Asian</option>
                  <option>Caucasian</option>
                  <option>Hispanic</option>
                  <option>Perfer Not to Say</option>
                  <option>Other</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label mt-4">
                  Are you a military veteran?
                </label>
                <select
                  type="text"
                  className="form-control"
                  id="business"
                  placeholder="Your answer"
                  onChange={(e) => setMilitary(e.target.value)}
                  value={military}
                >
                  <option>Pick One</option>
                  <option>Yes</option>
                  <option>No</option>
                  <option>
                    Currently serving as active duty, reserve, or national guard
                  </option>
                </select>
              </div>

              {military !== "Pick One" && military !== "No" && (
                <>
                  <div className="form-group">
                    <label className="form-label mt-4">
                      What branch did you serve or are you currently serving in?
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="business"
                      placeholder="Your answer"
                      onChange={(e) => setBranch(e.target.value)}
                      value={branch}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label mt-4">
                      What is your current status:
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      id="business"
                      placeholder="Your answer"
                      onChange={(e) => setJobStatus(e.target.value)}
                      value={jobStatus}
                    >
                      <option>Active</option>
                      <option>Retired</option>
                    </select>
                  </div>
                </>
              )}

              <div className="form-group">
                <label className="form-label mt-4">
                  What agency do you work for?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="job"
                  placeholder="Your answer"
                  onChange={(e) => setJob(e.target.value)}
                  value={job}
                />
              </div>
              <div className="form-group">
                <label className="form-label mt-4">
                  What is your current job function?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="jobFunction"
                  placeholder="Your answer"
                  onChange={(e) => setJobFunction(e.target.value)}
                  value={jobFunction}
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            </fieldset>
            <label>{errorLabel}</label>
          </form>
        </div>
      </div>
    </>
  );
}

export default Settings;
