import React from "react";

const DonationCards = ({ paymentDate, name, email, message, amount }) => {
  return (
    <div
      className="card border-primary mb-3"
      style={{ maxWidth: "20rem", margin: "20px" }}
    >
      <p className="card-text">{paymentDate}</p>
      <div className="card-header">${amount}</div>
      <div className="card-body">
        <h4 className="card-title">{name}</h4>
        <a href={"mailto:max.mustermann@example.com"}>{email}</a>
        <p className="card-text">{message}</p>
      </div>
    </div>
  );
};

export default DonationCards;
