import React, { useState, useEffect } from "react";
import { useCollection } from "../../hooks/useCollection";
import UserEventCard from "./UserEventCard";

const UserEvents = ({ userUID }) => {
  const { documents, error } = useCollection("RSVP");
  const [filteredRSVP, setFilterRSVP] = useState([]);

  useEffect(() => {
    if (documents) {
      const testing = documents.filter((event) => event.user === userUID);
      console.log(testing);
      setFilterRSVP(testing);
    }
  }, [documents, userUID]);

  return (
    <div className="user-list" style={{ marginTop: "50px" }}>
      <h3>Events You have RSVP To</h3>
      {error && <div>You Do Not Have Any Events</div>}
      {document &&
        filteredRSVP.map((rsvp) => (
          <UserEventCard eventUID={rsvp.event} rsvpId={rsvp.id} />
        ))}
    </div>
  );
};

export default UserEvents;
