import React from "react";
import ProductCard from "../../components/ProductCard";
import { useCollection } from "../../hooks/useCollection";
import { Col, Row } from "react-bootstrap";

import "./Store.css";

const Store = () => {
  const { isPending, error, documents } = useCollection("store");
  return (
    <div className="grid-container">
      <h1 style={{ marginTop: "25px" }}>Coming Soon in 2023!</h1>
      <Row>
        {documents &&
          documents.map((product) => (
            <Col sm={12} md={6} lg={6} xl={6}>
              <ProductCard
                productDescription={product.productDescription}
                productName={product.productName}
                productPrice={product.productPrice}
                productQty={product.productQty}
                productStatus={product.productStatus}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default Store;
