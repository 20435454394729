import React from "react";
import { useHistory } from "react-router";

const PhotoAssignmentTable = () => {
  const history = useHistory();

  const updatePhoto = (key) => {
    history.push(`/editPhoto/${key}`);
  };
  return (
    <div className="user-list">
      <h2>Edit Home Page Photos</h2>
      <table className="table table-hover">
        <thead>
          <tr className="table-dark">
            <th scope="col">Photos to Manage</th>
            <th></th>
          </tr>
        </thead>

        <tbody key={1} className="user-list-item">
          <tr className="table-light">
            <td>Photo One</td>
            <td>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => updatePhoto(1)}
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>

        <tbody key={1} className="user-list-item">
          <tr className="table-light">
            <td>Photo Two</td>
            <td>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => updatePhoto(2)}
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>

        <tbody key={1} className="user-list-item">
          <tr className="table-light">
            <td>Photo Three</td>
            <td>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => updatePhoto(3)}
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PhotoAssignmentTable;
