import React from "react";
import { useDocument } from "../../hooks/useDocument";
import { useFirestore } from "../../hooks/useFirestore";

const UserEventCard = ({ eventUID, rsvpId }) => {
  const { document } = useDocument("events", eventUID);
  const { deleteDocument } = useFirestore("RSVP");

  const handleDeleteNotification = async () => {
    deleteDocument(rsvpId);
  };

  return (
    <>
      {document && (
        <div className="alert alert-dismissible alert-warning">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            onClick={handleDeleteNotification}
          ></button>
          <h4 className="alert-heading">{document.eventName}</h4>
          <p>
            {document.eventDate} {document.eventTime}
          </p>
          <p className="mb-0">{document.eventDescription}</p>
        </div>
      )}
    </>
  );
};

export default UserEventCard;
