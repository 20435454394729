import React from "react";
import PhotoCarousel from "../components/PhotoCarousel/PhotoCarousel";
import First from "../components/HomePage/First";
import Second from "../components/HomePage/Second";
import Third from "../components/HomePage/Third";
import logo from "../logo.jpg";
import { Col, Row } from "react-bootstrap";
import Filter from "../components/Filter/Filter";
import EventCard from "../components/EventCard";

const Home = () => {
  var link = <a href={"https://www.goalny.org/"}>GOALny</a>;

  return (
    <>
      <h1 style={{ color: "rgb(233,144,0)", textAlign: "center" }}>
        Gay Officers Action League Central Florida
      </h1>
      <img
        className="d-block w-100"
        src={logo}
        alt="goal logo"
        style={{ width: "160px", height: "160px", marginLeft: "43.2%" }}
      />
      <PhotoCarousel />
      <div>
        <div
          style={{
            background:
              "linear-gradient(to right, #D82427 0, #D82427 20%, #F16C24 20%, #F16C24 40%, #F9D126 40%, #F9D126 60%, #1E9F4A 60%, #1E9F4A 80%, #2D3962 80%, #2D3962 100%)",
            height: "42px",
          }}
        ></div>
        <div style={{ color: "rgb(233,144,0)", textAlign: "center" }}>
          <h1>Our Mission</h1>
        </div>
        <p>
          The mission of GOAL(cfl) is to support, educate, and promote a
          positive relationship between LGBTQ law enforcement professionals and
          the communities in which we work, live and serve.
        </p>
      </div>
      <div>
        <div
          style={{
            background:
              "linear-gradient(to right, #61CADA 0, #61CADA 33%, #F199BF 33%, #F199BF 66%, #61CADA 66%, #61CADA 100%)",
            height: "42px",
          }}
        ></div>
        <div style={{ color: "rgb(233,144,0)", textAlign: "center" }}>
          <h1>Who We Are</h1>
        </div>
        <p>
          Formed in 2019 as a fraternal civil rights organization, the Gay
          Officers Action League Central Florida (GOALcfl) addresses the needs,
          issues, and concerns of Lesbian, Gay, Bisexual, Transgender and Queer
          (LGBTQ) law enforcement personnel, both active duty and retired, as
          well as the criminal justice professionals who support them. Our goal
          is to advocate for the rights of its members and assist them on
          matters of discrimination, harassment, and disparate treatment in the
          workplace.{" "}
        </p>
        <p>
          Our organization provides members with an arena to discuss their needs
          and concerns in a comfortable atmosphere without the fear of
          job-related reprisals. Some of our members have been, and continue to
          be, victims of harassment and discrimination in the workplace and
          communities in which they serve. However many of members are fortunate
          to work and live in a supportive and accepting environment. Our goal
          is to be a safe place for all.
        </p>
        <p>
          Modeled after {link}, who was the first organization of its kind, we
          strive for a high level of diversity and the inclusion of all people
          within the organization. We aim to stimulate intellectual growth and
          self-awareness of our members. In doing so, we hope to motivate our
          members to achieve a higher degree of efficiency in the discharge of
          their duties as law enforcement and criminal justice professionals.
        </p>
        <p>
          While the GOALcfl is a positive force within the LGBTQ community, it
          is also instrumental in attempting to change homophobic attitudes
          within the workplace and the community at large. We serve as a bridge
          between the law enforcement professionals, criminal justice
          professionals and those we serve.
        </p>
      </div>
      <div>
        <div
          style={{
            background:
              "linear-gradient(to right, #D82427 0, #D82427 20%, #F16C24 20%, #F16C24 40%, #F9D126 40%, #F9D126 60%, #1E9F4A 60%, #1E9F4A 80%, #2D3962 80%, #2D3962 100%)",
            height: "42px",
          }}
        ></div>
        <div style={{ color: "rgb(233,144,0)", textAlign: "center" }}>
          <h1>What We Do</h1>
        </div>
        <Row>
          <Col sm={12} md={6} lg={4} xl={3}>
            <First />
          </Col>
          <Col sm={12} md={6} lg={4} xl={3}>
            <Second />
          </Col>
          <Col sm={12} md={6} lg={4} xl={3}>
            <Third />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Home;
