import React, { useState } from "react";
import {
  SDivider,
  SLink,
  SLinkContainer,
  SLinkIcon,
  SLinkLabel,
  SLinkNotification,
  SSidebar,
  SSidebarButton,
} from "./styles";

import {
  AiOutlineHome,
  AiOutlineLeft,
  AiOutlineShopping,
} from "react-icons/ai";
import { BiFirstAid, BiStats } from "react-icons/bi";
import { IoStorefrontOutline } from "react-icons/io5";
import { SiGithubsponsors } from "react-icons/si";
import { RiAdminLine } from "react-icons/ri";
import { FaRegHandshake } from "react-icons/fa";
import {
  MdLogout,
  MdPeopleOutline,
  MdOutlineEventAvailable,
  MdOutlinePhotoSizeSelectActual,
  MdOutlineAttachMoney,
  MdPerson,
} from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";

const Sidebar = ({ handleClick, type }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <SSidebar isOpen={sidebarOpen}>
      <>
        <SSidebarButton
          isOpen={sidebarOpen}
          onClick={() => setSidebarOpen((p) => !p)}
        >
          <AiOutlineLeft />
        </SSidebarButton>
      </>
      <SDivider />
      {linksArray.map(({ icon, label, notification }) => (
        <SLinkContainer key={label}>
          <SLink
            style={!sidebarOpen ? { width: `fit-content` } : {}}
            onClick={() => handleClick(label)}
          >
            <SLinkIcon>{icon}</SLinkIcon>
            {sidebarOpen && (
              <>
                <SLinkLabel>{label}</SLinkLabel>
                {/* if notifications are at 0 or null, do not display */}
                {!!notification && (
                  <SLinkNotification>{notification}</SLinkNotification>
                )}
              </>
            )}
          </SLink>
        </SLinkContainer>
      ))}
      <SDivider />
      {type === "admin" && (
        <div>
          {adminArray.map(({ icon, label, notification }) => (
            <SLinkContainer key={label}>
              <SLink
                style={!sidebarOpen ? { width: `fit-content` } : {}}
                onClick={() => handleClick(label)}
              >
                <SLinkIcon>{icon}</SLinkIcon>
                {sidebarOpen && (
                  <>
                    <SLinkLabel>{label}</SLinkLabel>
                    {/* if notifications are at 0 or null, do not display */}
                    {!!notification && (
                      <SLinkNotification>{notification}</SLinkNotification>
                    )}
                  </>
                )}
              </SLink>
            </SLinkContainer>
          ))}
          <SDivider />
        </div>
      )}
      {secondaryLinksArray.map(({ icon, label }) => (
        <SLinkContainer key={label}>
          <SLink
            style={!sidebarOpen ? { width: `fit-content` } : {}}
            onClick={() => handleClick(label)}
          >
            <SLinkIcon>{icon}</SLinkIcon>
            {sidebarOpen && <SLinkLabel>{label}</SLinkLabel>}
          </SLink>
        </SLinkContainer>
      ))}
      <SDivider />
    </SSidebar>
  );
};

const linksArray = [
  {
    label: "Home",
    icon: <AiOutlineHome />,
    notification: 0,
  },
  {
    label: "My Orders",
    icon: <AiOutlineShopping />,
    notification: 0,
  },
  {
    label: "Dues",
    icon: <TbReportMoney />,
    notification: 0,
  },
  {
    label: "Support",
    icon: <FaRegHandshake />,
    notification: 0,
  },
];

const adminArray = [
  {
    label: "Members",
    icon: <MdPeopleOutline />,
    notification: 0,
  },
  {
    label: "Board Members",
    icon: <RiAdminLine />,
    notification: 0,
  },
  {
    label: "Events",
    icon: <MdOutlineEventAvailable />,
    notification: 0,
  },
  {
    label: "Sponsors",
    icon: <SiGithubsponsors />,
    notification: 0,
  },
  {
    label: "Store",
    icon: <IoStorefrontOutline />,
    notification: 0,
  },
  {
    label: "Photos",
    icon: <MdOutlinePhotoSizeSelectActual />,
    notification: 0,
  },
  {
    label: "Donations",
    icon: <MdOutlineAttachMoney />,
    notification: 0,
  },
  {
    label: "Help Requests",
    icon: <BiFirstAid />,
    notification: 0,
  },
  {
    label: "Member Stats",
    icon: <BiStats />,
    notification: 0,
  },
];

const secondaryLinksArray = [
  {
    label: "Profile",
    icon: <MdPerson />,
  },
  {
    label: "Logout",
    icon: <MdLogout />,
  },
];

export default Sidebar;
