import React from "react";
import { useFirestore } from "../../hooks/useFirestore";

const EventSugRow = ({
  eventName,
  eventDescription,
  eventContact,
  eventPhone,
  eventWebsite,
  id,
}) => {
  const { deleteDocument } = useFirestore("eventSuggestion");

  const cancelEvent = () => {
    console.log(id);
    deleteDocument(id);
    //TODO what happens with event closed and a user has RSVP'd?
  };

  return (
    <tr className="table-light">
      <td>{eventName}</td>
      <td>{eventDescription}</td>
      <td>{eventContact}</td>
      <td>{eventPhone}</td>
      <td>{eventWebsite}</td>
      <td>
        <button type="submit" className="btn btn-primary" onClick={cancelEvent}>
          Delete
        </button>
      </td>
    </tr>
  );
};

export default EventSugRow;
