import React from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";

function Header() {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const logoutHandler = () => {
    logout();
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Goalcfl</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/board">
              <Nav.Link>Meet the Board</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/store">
              <Nav.Link>Store</Nav.Link>
            </LinkContainer>
            {/* TODO change dropdown from transparent to solid color */}
            <NavDropdown
              title="Community Involvement"
              id="collasible-nav-dropdown"
            >
              {user && (
                <LinkContainer to="/events">
                  <NavDropdown.Item>Upcoming Events</NavDropdown.Item>
                </LinkContainer>
              )}
              {!user && (
                <>
                  <LinkContainer to="/upcomingEvents">
                    <NavDropdown.Item>Upcoming Events</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/membership">
                    <NavDropdown.Item>Become a Member</NavDropdown.Item>
                  </LinkContainer>
                </>
              )}
              <LinkContainer to="/sponsors">
                <NavDropdown.Item>Our Sponsors</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/becomeSponsor">
                <NavDropdown.Item>Become a Sponser</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/suggestEvent">
                <NavDropdown.Item>Suggest an Event</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="/contact">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav>
            {!user && (
              <>
                <LinkContainer to="login">
                  <Nav.Link eventKey={2}>Sign In</Nav.Link>
                </LinkContainer>
              </>
            )}
            {user && (
              <>
                <LinkContainer to="/dashboard">
                  <Nav.Link eventKey={2}>Dashboard</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/">
                  <Nav.Link onClick={logoutHandler}>Log Out</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
