import React from "react";
import { Row } from "react-bootstrap";

const Platinum = () => {
  return (
    <div>
      <div className="grid-container">
        <div
          className="card mb-3 mt-3"
          style={{
            maxWidth: "30rem",
            backgroundColor: "rgb(166, 169, 171)",
            height: "530px",
          }}
        >
          <div className="card-header" style={{ fontSize: "28px" }}>
            Platinum Sponsor{" "}
            <Row style={{ fontSize: "20px", paddingLeft: "10px" }}>
              ($5,000 or more)
            </Row>
          </div>
          <div className="card-body">
            <p className="card-text">
              <dl>
                <dt>For 1 Year, Corporate Name and Logo on:</dt>
                <dd>
                  All advertising and publicity
                  <br></br>GOALcfl banner (appears at public events)
                  <br></br>Website in Corporate Sponsors section
                </dd>
                <dt>Social Media</dt>
                <dd> Donor acknowledgement</dd>
                <dt>Plaque</dt>
                <dd>Large Plaque of Appreciation</dd>
                <dt>Community</dt>
                <dd>
                  15 Memberships
                  <br></br>15 T-shirts
                  <br></br>Table at all community event
                </dd>
              </dl>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platinum;
