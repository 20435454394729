import React from "react";
import BoardCard from "../../components/BoardCard";
import { useCollection } from "../../hooks/useCollection";
import { Col, Row } from "react-bootstrap";

import "./Board.css";

const Board = () => {
  const { documents } = useCollection("users");

  return (
    <div className="grid-container">
      <Row>
        {documents &&
          documents.map(
            (admin) =>
              admin.board && (
                <>
                  <Col key={admin.id} sm={12} md={6} lg={6} xl={6}>
                    <BoardCard
                      firstName={admin.firstName}
                      lastName={admin.lastName}
                      email={admin.email}
                      position={admin.position}
                      phoneNumber={admin.phoneNumber}
                      bio={admin.bio}
                      image={admin.pictureURL}
                      className="grid-item"
                    />
                  </Col>
                </>
              )
          )}
      </Row>
    </div>
  );
};

export default Board;
