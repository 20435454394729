import React from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { useHistory } from "react-router";

const ProductTableRow = ({
  productName,
  description,
  price,
  qty,
  status,
  id,
}) => {
  const { deleteDocument } = useFirestore("store");
  const history = useHistory();

  const removeProduct = () => {
    deleteDocument(id);
  };
  const editProduct = () => {
    history.push(`/editProduct/${id}`);
  };
  return (
    <tr className="table-light">
      <td>{productName}</td>
      <td>{description}</td>
      <td>{price}</td>
      <td>{qty}</td>
      <td>{status}</td>
      <td>
        <button type="submit" className="btn btn-info" onClick={editProduct}>
          Edit
        </button>
      </td>
      <td>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={removeProduct}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default ProductTableRow;
