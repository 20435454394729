// TODO
//  limit file type for images and return a "doesn't accept file type, please submit a .xxy or .xyz"
//  Add location to event

import React from "react";

const LoggedOutEventCard = ({
  eventName,
  eventDescription,
  eventDate,
  eventTime,
  eventURL,
  rsvp,
  rsvps,
  id,
}) => {
  return (
    <>
      <div
        style={{ maxWidth: "100%", margin: "30px" }}
        className="card text-white bg-info mb-3"
      >
        <img
          src={eventURL}
          alt="company-logo"
          style={{
            padding: "10px",
            backgroundColor: "rgb(238, 238, 238)",
            objectFit: "scale-down",
            height: "300px",
          }}
        />
        <div className="card-header">
          {eventDate} {eventTime}
        </div>
        <div className="card-body">
          <h4 className="card-title">{eventName}</h4>
          <p className="card-text">{eventDescription}</p>
        </div>
        {rsvp !== "none" && (
          <button type="submit" className="btn btn-primary">
            RSVP Required: Log In to RSVP
          </button>
        )}
      </div>
    </>
  );
};

export default LoggedOutEventCard;
