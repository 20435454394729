import React from "react";
import { Row } from "react-bootstrap";

const Third = () => {
  return (
    <div
      className="card mb-3 mt-3"
      style={{ maxWidth: "30rem", paddingTop: "12px" }}
    >
      <div className="card-header" style={{ fontSize: "20px" }}>
        Promote a positive relationship between the criminal justice and the
        LGBTQ communities
        <Row style={{ fontSize: "20px", paddingLeft: "10px" }}></Row>
      </div>
      <div className="card-body">
        <p className="card-text">
          <ul>
            <li>
              Perform community service work with Central Florida Organizations
            </li>
            <li>Educational Forums</li>
            <li>Participate in Community Events</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Third;
