import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLogin } from "../../hooks/useLogin";
import firebase from "firebase";

import "./signin.css";

const SignIn = () => {
  const history = useHistory();
  const { login, error } = useLogin();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [alertColor, setAlertColor] = useState("");

  useEffect(() => {
    if (error) {
      setErrorTitle("ERROR");
      setErrorMessage(error);
      setAlertColor("alert-danger");
      setShowNotification(true);
    }
  }, [error]);

  const handleSignIn = (e) => {
    e.preventDefault();
    setShowNotification(false);
    if (email !== "" && password !== "") {
      login(email, password);
    }
  };

  const handleForgotPassword = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        if (email !== "") {
          console.log("email sent");
          setErrorTitle("Email Sent!");
          setAlertColor("alert-success");
          setErrorMessage(
            "A reset email has been set, please be sure to check your spam folder. The email will be sent from noreply@goalcfl.firebaseapp.com"
          );
          setShowNotification(true);
        }
      })
      .catch((error) => {
        var errorMessage = error.message;
        setErrorTitle("ERROR");
        setAlertColor("alert-danger");
        setErrorMessage(errorMessage);
        setShowNotification(true);
      });
  };

  return (
    <>
      {showNotification && (
        <div
          className={`alert alert-dismissible ${alertColor}`}
          style={{ marginTop: "10px" }}
        >
          <h4 className="alert-heading">{errorTitle}</h4>
          <p className="mb-0">{errorMessage}</p>
        </div>
      )}

      <div className="card logInForm">
        <div className="card-body">
          <h4 className="card-title">SignIn</h4>
          <fieldset>
            <div className="form-group">
              <label className="form-label mt-4">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Password</label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary loginButton"
                onClick={handleSignIn}
              >
                Sign In
              </button>
              <button
                type="submit"
                className="btn btn-success joinButton"
                onClick={() => history.push("/membership")}
              >
                Join
              </button>
            </div>
          </fieldset>
          <div className="card-body passwordReset">
            <button
              onClick={handleForgotPassword}
              className="card-link btn btn-warning"
            >
              Forgot Password?
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
