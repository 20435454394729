import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCyoEJDsa9ORahAaqXoVLL6GTjF-D95MxM",
  authDomain: "goalcfl.firebaseapp.com",
  projectId: "goalcfl",
  storageBucket: "goalcfl.appspot.com",
  messagingSenderId: "607752963201",
  appId: "1:607752963201:web:97207cac554fc87278b980",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init servies
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();

// timestamp
const timestamp = firebase.firestore.Timestamp;
const db = firebase.firestore();
const auth = firebase.auth();

export { auth, projectAuth, projectFirestore, projectStorage, timestamp };
export default db;
