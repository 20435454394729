import React, { useState } from "react";
import MemberTable from "../components/Dashboard/MemberTable.js";
import SponsorTable from "../components/Dashboard/SponsorTable.js";
import firebase from "firebase/app";
import { useDocument } from "../hooks/useDocument";
import AdminTable from "../components/Dashboard/AdminTable.js";
import EventTable from "../components/Dashboard/EventTable.js";
import ProductTable from "../components/Dashboard/ProductTable.js";
import SponsorInqTable from "../components/Dashboard/SponsorInqTable.js";
import EventSugTable from "../components/Dashboard/EventSugTable.js";
import PhotoAssignmentTable from "../components/Dashboard/PhotoAssignmentTable.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Settings from "../components/Dashboard/Settings.js";
import Dues from "../components/UserDashboard/Dues";
import MyOrders from "../components/UserDashboard/MyOrders";

import { useLogout } from "../hooks/useLogout";
import { useHistory } from "react-router";
import Notifications from "../components/UserDashboard/Notifications.js";
import UserEvents from "../components/UserDashboard/UserEvents.js";
import UserModal from "../components/UserModal/UserModal";
import Donations from "./Donations/Donations.js";
import MemberHelp from "../components/UserDashboard/MemberHelp/MemberHelp.js";
import HelpRequest from "../components/Dashboard/HelpRequest.js";
import MemberStats from "../components/Dashboard/MemberStats.js";

const Dashboard = () => {
  let currentUser = firebase.auth().currentUser;
  const [activeTab, setActiveTab] = useState("Home");

  const { document } = useDocument("users", currentUser.uid);
  const { logout } = useLogout();
  const [showModal, setShowModal] = useState(false);
  const [userUID, setUserUID] = useState("");
  const history = useHistory();

  const handleShowModal = (userUID) => {
    setUserUID(userUID);
    setShowModal(!showModal);
  };

  const handleClick = (label) => {
    setActiveTab(label);
    if (label === "Logout") {
      logout();
      history.push("/");
    }
  };

  // SUBSCRIPTION
  // const checkOut = async (priceId) => {
  //   console.log("checkout");
  //   const docRef = await db
  //     .collection("customers")
  //     .doc(currentUser.uid)
  //     .collection("checkout_sessions")
  //     .add({
  //       price: priceId,
  //       success_url: window.location.origin,
  //       cancel_url: window.location.origin,
  //     });
  //   docRef.onSnapshot(async (snap) => {
  //     const { error, sessionId } = snap.data();
  //     if (error) {
  //       console.log("error");
  //       alert(error.message);
  //     }
  //     if (sessionId) {
  //       console.log("sessionId");
  //       const stripe = await loadStripe(
  //          process.env.REACT_APP_STRIPE_ACCESS
  //       );
  //       stripe.redirectToCheckout({ sessionId });
  //     }
  //   });
  // };

  return (
    <div style={{ display: "flex" }}>
      {showModal && (
        <UserModal
          showModal={showModal}
          setShowModal={setShowModal}
          userUID={userUID}
        />
      )}
      {document && document.admin === true ? (
        <>
          <Sidebar handleClick={handleClick} type={"admin"} />
          <div style={{ width: "100%", paddingTop: "25px" }}>
            {activeTab === "Home" && (
              <>
                <h1>Welcome {document.firstName}!</h1>
                <div
                  style={{
                    marginTop: "25px",
                    marginBottom: "25px",
                    backgroundColor: "lightGray",
                  }}
                >
                  <h3>A note from your website developer!💻 </h3>
                  <p>
                    Added the ability to delete a user - select "View Profile &
                    Edit" then "Delete"
                  </p>
                  <p>
                    Added Solicitation form to the Sponsor Page - scroll to the
                    bottom of the page to see the link to download it
                  </p>
                  <p>
                    Can now submit multiple help request forms and view status
                    of all of your submissions
                  </p>
                </div>
                <Notifications showButton={true} />
                <UserEvents userUID={currentUser.uid} />
              </>
            )}
            {activeTab === "Members" && (
              <MemberTable handleViewProfile={(id) => handleShowModal(id)} />
            )}
            {activeTab === "Photos" && <PhotoAssignmentTable />}
            {activeTab === "Support" && (
              <MemberHelp userUID={currentUser.uid} />
            )}
            {activeTab === "Help Requests" && <HelpRequest />}
            {activeTab === "Member Stats" && <MemberStats />}
            {activeTab === "Sponsors" && <SponsorTable />}
            {activeTab === "Sponsors" && <SponsorInqTable />}
            {activeTab === "Board Members" && <AdminTable />}
            {activeTab === "Events" && <EventTable />}
            {activeTab === "Events" && <EventSugTable />}
            {activeTab === "Store" && <ProductTable />}
            {activeTab === "Donations" && <Donations />}
            {activeTab === "Profile" && <Settings userUID={currentUser.uid} />}
            {activeTab === "My Orders" && (
              <MyOrders userUID={currentUser.uid} />
            )}
            {activeTab === "Dues" && <Dues userUID={currentUser.uid} />}
          </div>
        </>
      ) : (
        document && (
          <>
            <Sidebar handleClick={handleClick} type={"user"} />
            <div style={{ width: "100%", paddingTop: "25px" }}>
              {activeTab === "Home" && (
                <>
                  <h1>Welcome {document.firstName}!</h1>
                  <Notifications showButton={false} />
                  <UserEvents userUID={currentUser.uid} />
                </>
              )}
              {activeTab === "Profile" && (
                <Settings userUID={currentUser.uid} />
              )}
              {activeTab === "My Orders" && (
                <MyOrders userUID={currentUser.uid} />
              )}
              {activeTab === "Dues" && <Dues userUID={currentUser.uid} />}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default Dashboard;
