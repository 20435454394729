import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./description.css";

const Description = () => {
  return (
    <div>
      <div>
        <h3 className="descriptionHeader">Membership Benifits</h3>
        <ul className="descriptionList">
          <li>Open attendance to all monthly meetings and events</li>
          <li>Discounted admission to events</li>
          <li>Email updates, E-newsletters and mails</li>
          <li>
            Voting priviledges in executive board elections *Professional Only*
          </li>
        </ul>
      </div>

      <Container>
        <Row>
          <Col>
            <div
              className="card text-white bg-success mb-3"
              style={{ maxWidth: "20rem;", height: "250px" }}
            >
              <div className="card-header">Professional Membership</div>
              <div className="card-body">
                <p className="card-text">
                  Open to any person who is presently or has ever been a
                  full-time law enforcement officer or a full-time paid employee
                  of any agency of the criminal justice system. Full benefits of
                  Professional Membership will be extended after verification.
                </p>
                <p className="card-text">Membership fee is $30.00/yearly</p>
              </div>
            </div>
          </Col>
          <Col>
            <div
              className="card text-white bg-warning mb-3"
              style={{ maxWidth: "20rem;", height: "250px" }}
            >
              <div className="card-header">Associate Membership</div>
              <div className="card-body">
                <p className="card-text">
                  Open to anyone (non law enforcement or criminal justice
                  professional) who supports the organization and its mission.
                </p>
                <p className="card-text">Membership fee is $15.00/yearly</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Description;
