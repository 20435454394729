import React from "react";
import DonationCards from "../../components/DonationCards/DonationCards";
import { useCollection } from "../../hooks/useCollection";
import { Col, Row } from "react-bootstrap";

import "./Donations.css";

const Donations = () => {
  const { documents } = useCollection("donor");

  return (
    <div className="grid-container">
      <Row>
        {documents &&
          documents.map((donation) => (
            <>
              <Col key={donation.id} sm={12} md={6} lg={6} xl={6}>
                <DonationCards
                  paymentDate={donation.paymentDate}
                  email={donation.email}
                  amount={donation.amount}
                  name={donation.name}
                  message={donation.message}
                />
              </Col>
            </>
          ))}
      </Row>
    </div>
  );
};

export default Donations;
