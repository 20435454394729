import React, { useEffect, useState } from "react";
import "../components/Membership/memberForm.css";
import { useFirestore } from "../hooks/useFirestore";
import { useCollection } from "../hooks/useCollection";
import { useHistory } from "react-router-dom";

function CreateAdmin() {
  const history = useHistory();
  const { documents } = useCollection("users");
  const { updateDocument, response } = useFirestore("users");

  const [position, setPosition] = useState("");
  const [uid, setUid] = useState("");

  useEffect(() => {
    if (documents) {
      documents.map((user, i) => {
        if (i === 0) {
          setUid(user.uid);
        }
      });
    }
  }, [documents]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    //Update user to show admin true
    const adminProp = {
      board: true,
      position,
    };

    await updateDocument(uid, adminProp);

    if (!response.error) {
      history.push("/dashboard");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Add New Board Member</h4>
        <form>
          <fieldset>
            <div className="form-group">
              <label className="form-label mt-4">Position</label>
              <input
                type="text"
                className="form-control"
                id="position"
                placeholder="Enter Position"
                onChange={(e) => setPosition(e.target.value)}
                value={position}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">User</label>

              <select
                className="form-select"
                id="user"
                placeholder="Select User"
                onChange={(e) => setUid(e.target.value)}
                value={uid}
              >
                {documents &&
                  documents.map((user) => (
                    <option key={user.uid} value={user.uid}>
                      {user.firstName} {user.lastName}
                    </option>
                  ))}
              </select>
            </div>
            <button
              type="submit"
              className="btn btn-success"
              onClick={handleSubmit}
            >
              Add
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default CreateAdmin;
