import React, { useEffect, useState } from "react";
import { useFirestore } from "../../hooks/useFirestore";

const MemberTableRow = ({
  firstName,
  lastName,
  email,
  admin,
  board,
  dues,
  id,
  handleOnClick,
  memberId,
}) => {
  let userType = "member";

  if (admin) {
    userType = "admin";
  }
  if (board) {
    userType = "board";
  }
  if (admin & board) {
    userType = "admin & board";
  }

  return (
    <tr className="table-light">
      <td>{memberId}</td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{email}</td>
      <td>{userType}</td>
      <td>{dues}</td>
      <td>
        <button
          type="submit"
          className="btn btn-info"
          onClick={() => handleOnClick(id)}
        >
          View Profile & Edit
        </button>
      </td>
    </tr>
  );
};

export default MemberTableRow;
