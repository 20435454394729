// TODO
//  limit file type for images and return a "doesn't accept file type, please submit a .xxy or .xyz"
//  Add location to event

import React, { useEffect, useState } from "react";
import { useFirestore } from "../hooks/useFirestore";
import { useAuthContext } from "../hooks/useAuthContext";
import { useDocument } from "../hooks/useDocument";
import { useCollection } from "../hooks/useCollection";

const EventCard = ({
  eventName,
  eventDescription,
  eventDate,
  eventTime,
  eventURL,
  rsvp,
  id,
}) => {
  const { user } = useAuthContext();
  const [buttonText, setButtonText] = useState("RSVP");
  const [rsvpAlready, setRsvpAlready] = useState(false);
  const { addDocument } = useFirestore("RSVP");
  const { document } = useDocument("users", user.uid);
  const { documents } = useCollection("RSVP");

  useEffect(() => {
    if (documents) {
      documents.map((rsvp) => {
        if (rsvp.event === id) {
          setRsvpAlready(true);
        }
      });
    }
  }, [documents, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (user.uid && document) {
      if (buttonText === "RSVP") {
        if (rsvp === "one") {
          await addDocument({
            user: user.uid,
            email: user.email,
            firstName: document.firstName,
            lastName: document.lastName,
            event: id,
            eventDate,
            eventName,
            eventTime,
          });
          console.log("DONE");

          setButtonText("RSVP Sent!");
        } else {
          //TODO create page to select how many people will attend
        }
      }
    } else {
      setButtonText("ERROR: Please log in first!");
    }
  };
  return (
    <div
      style={{ maxWidth: "100%", margin: "30px" }}
      className="card text-white bg-info mb-3"
    >
      <img
        src={eventURL}
        alt="company-logo"
        style={{
          padding: "10px",
          backgroundColor: "rgb(238, 238, 238)",
          objectFit: "scale-down",
          height: "300px",
        }}
      />
      <div className="card-header">
        {eventDate} {eventTime}
      </div>
      <div className="card-body">
        <h4 className="card-title">{eventName}</h4>
        <p className="card-text">{eventDescription}</p>
      </div>
      {rsvp !== "none" && (
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
          style={{ marginBottom: "5px" }}
          disabled={rsvpAlready === true}
        >
          {rsvpAlready === true ? "RSVP Sent" : buttonText}
        </button>
      )}
    </div>
  );
};

export default EventCard;
