import React, { useEffect, useState } from "react";
import EventCard from "../components/EventCard";
import { useCollection } from "../hooks/useCollection";
import Filter from "../components/Filter/Filter";

import "./Board/Board.css";

const Events = () => {
  const { documents } = useCollection("events");
  const { documents: RSVP } = useCollection("RSVP");

  let sorted = [];

  if (documents) {
    sorted = documents.sort((a, b) => (a.eventDate > b.eventDate ? 1 : -1));
  }

  console.log(sorted);

  const [filter, setFilter] = useState("All");

  const changeFilter = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <>
      <Filter changeFilter={changeFilter} />
      <h1
        style={{ marginTop: "8px", marginBottom: "-5px", textAlign: "center" }}
      >
        {filter === "All" ? "All Events" : `Events for ${filter}`}
      </h1>
      {documents &&
        RSVP &&
        sorted.map((event) =>
          filter !== "All" ? (
            event.month === filter && (
              <>
                <EventCard
                  className="card"
                  key={event.id}
                  eventDate={event.eventDate}
                  eventDescription={event.eventDescription}
                  eventName={event.eventName}
                  eventTime={event.eventTime}
                  eventURL={event.eventURL}
                  rsvp={event.rsvp}
                  id={event.id}
                />
              </>
            )
          ) : (
            <>
              <EventCard
                className="card"
                key={event.id}
                eventDate={event.eventDate}
                eventDescription={event.eventDescription}
                eventName={event.eventName}
                eventTime={event.eventTime}
                eventURL={event.eventURL}
                rsvp={event.rsvp}
                id={event.id}
              />
            </>
          )
        )}
    </>
  );
};

export default Events;
