import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirestore } from "../../hooks/useFirestore";
import { projectStorage } from "../../firebase/config";
import { useParams } from "react-router-dom";
import "../Membership/memberForm.css";

function ProfilePicture() {
  const history = useHistory();
  const { uid } = useParams();

  const [errorLabel, setErrorLabel] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);

  const { updateDocument, response } = useFirestore("users");

  const handleFileChange = (e) => {
    setThumbnail(null);
    let selected = e.target.files[0];

    if (!selected) {
      setThumbnailError("Please select a file");
      return;
    }
    if (!selected.type.includes("image")) {
      setThumbnailError("Selected file must be an image");
      return;
    }

    setThumbnailError(null);
    setThumbnail(selected);
    console.log(thumbnail);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ((thumbnail !== null) | (uid !== null)) {
      let imgUrl = "";
      if (thumbnail !== null) {
        const uploadPath = `profilePictures/${thumbnail.name}`;
        const img = await projectStorage.ref(uploadPath).put(thumbnail);
        imgUrl = await img.ref.getDownloadURL();
        const photo = {
          pictureURL: imgUrl,
        };

        await updateDocument(uid, photo);
        if (!response.error) {
          history.push("/dashboard");
        }
      }
    } else {
      setErrorLabel("ERROR: Please upload a photo!");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Upload a New Profile Picture</h4>
        <label>
          <span>Upload Image: </span>
          <input required type="file" onChange={handleFileChange} />
          {thumbnailError && <div className="error">{thumbnailError}</div>}
        </label>

        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
        >
          Upload
        </button>
        <label>{errorLabel}</label>
      </div>
    </div>
  );
}

export default ProfilePicture;
