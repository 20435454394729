import React from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { useHistory } from "react-router";

const EventTableRow = ({
  eventName,
  eventDescription,
  eventDate,
  eventTime,
  rsvp,
  id,
}) => {
  const { deleteDocument } = useFirestore("events");
  const history = useHistory();

  const editEvent = () => {
    history.push(`/editEvent/${id}`);
  };
  const cancelEvent = () => {
    deleteDocument(id);
    //TODO what happens with event closed and a user has RSVP'd?
  };
  const viewRSVP = () => {
    history.push(`/eventRSVP/${id}`);
  };
  let updatedRSVP = "";

  if (rsvp === "none") {
    updatedRSVP = "Not Required";
  }
  if (rsvp === "one") {
    updatedRSVP = "RSVP Required";
  }
  if (rsvp === "count") {
    updatedRSVP = "RSVP with Headcount";
  }
  return (
    <tr className="table-light">
      <td>{eventName}</td>
      <td>{eventDescription}</td>
      <td>{eventDate}</td>
      <td>{eventTime}</td>
      <td>{updatedRSVP}</td>
      <td>
        {rsvp !== "none" && (
          <button type="submit" className="btn btn-danger" onClick={viewRSVP}>
            View RSVP
          </button>
        )}
      </td>
      <td>
        <button type="submit" className="btn btn-info" onClick={editEvent}>
          Edit
        </button>
      </td>
      <td>
        <button type="submit" className="btn btn-primary" onClick={cancelEvent}>
          Remove
        </button>
      </td>
    </tr>
  );
};

export default EventTableRow;
