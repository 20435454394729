import React from "react";
import { useFirestore } from "../../hooks/useFirestore";

const NotificationBanner = ({ id, title, description, color, showButton }) => {
  const { deleteDocument } = useFirestore("notifications");
  const deleteNotification = () => {
    deleteDocument(id);
  };

  return (
    <div className={`alert alert-dismissible ${color}`}>
      <h4 className="alert-heading">{title}</h4>
      <p className="mb-0">{description}</p>
      {showButton && (
        <button
          type="button"
          className="btn btn-light"
          onClick={deleteNotification}
        >
          Delete for All Members
        </button>
      )}
    </div>
  );
};

export default NotificationBanner;
