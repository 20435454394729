function Training() {
  return (
    <div className="App">
      <div
        className="PDF-viewer"
        // style={{
        //   position: "relative",
        //   overflow: "hidden",
        //   width: "100%",
        //   paddingTop: "100%",
        // }}
      >
        <h3 style={{ paddingTop: "12px" }}>Training Agendas:</h3>
        {/* <iframe
          style={{
            // width: "90%",
            // height: "975px",
            position: "absolute",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            width: "100%",
            height: "100%",
          }}
          src="TrainingPDF.pdf"
          frameBorder="0"
        ></iframe> */}
        {/* 
        <object
          data="resume.pdf"
          type="application/pdf"
          width="100%"
          height="800px"
        > */}
        <p>
          <a href="TrainingPDF.pdf">
            December 15, 2023: LGBTQ+ Training for Criminal Justice
            Professionals & Allies
          </a>
        </p>
        <p>
          <a href="Swearing-In.pdf">
            January 17, 2024: GOAL Swearing-In Ceremony
          </a>
        </p>
        {/* </object> */}
      </div>
    </div>
  );
}

export default Training;
