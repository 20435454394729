import React from "react";
import { useHistory } from "react-router";
import { Container, Row, Col } from "react-bootstrap";

import "./contact.css";

const Contact = () => {
  const history = useHistory();

  return (
    <div>
      <div className="headerInfo">
        <h1>We'd Love to Hear from you!</h1>
        <p>
          Whether you're interested in becoming a member, have an event idea, or
          want to become a sponsor -- we're ready to answer any and all
          questions.
        </p>
      </div>

      <Container>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="card contactCard">
              <div className="card-body">
                <h4 className="card-title">Call Us</h4>
                <p className="card-text">
                  <a
                    href="tel:+1-407-434-9889"
                    style={{ textDecoration: "none" }}
                  >
                    (407) 434-9889
                  </a>
                </p>
                <p>Please leave a message and we will get back to you</p>
              </div>
            </div>
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="card contactCard">
              <div className="card-body">
                <h4 className="card-title">Mail Us</h4>
                <h6 className="card-subtitle mb-2 text-muted">
                  Gay Officers Action League Central Florida
                </h6>
                <p className="card-text">P.O. Box 1646</p>
                <p className="card-text" style={{ marginTop: "-19px" }}>
                  Orlando, Fl 32802
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="card contactCard">
              <div className="card-body">
                <h4 className="card-title">Email Us</h4>
                <p className="card-text">
                  For more information or to get in contact with GOALcfl,
                  contact one of our Board of Directors
                </p>
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={() => history.push("/board")}
                >
                  View Board Members
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
