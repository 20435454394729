import React from "react";
import { useCollection } from "../../hooks/useCollection";
import OrderRows from "./OrderRows";

const MyOrders = ({ userUID }) => {
  const { isPending, error, documents } = useCollection("user-orders");
  return (
    <div className="user-list">
      <h2>My Orders</h2>
      {isPending && <div>Loading Your Orders...</div>}
      {/*TODO {error && <div>You do not have any order history</div>} */}
      {error && <div>Coming SOON</div>}
      {documents && (
        <table className="table table-hover">
          <thead>
            <tr className="table-dark">
              <th scope="col">Status</th>
              <th scope="col">Order Date</th>
              <th scope="col">Order Number</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {documents.map((event) => (
            <tbody key={event.id} className="user-list-item">
              <OrderRows
                eventName={event.eventName}
                eventDescription={event.eventDescription}
                eventDate={event.eventDate}
                eventTime={event.eventTime}
                rsvp={event.rsvp}
                id={event.id}
                type={true}
              />
            </tbody>
          ))}
        </table>
      )}
    </div>
  );
};

export default MyOrders;
