import React from "react";
import { Row } from "react-bootstrap";

const Silver = () => {
  return (
    <div>
      {" "}
      <div className="grid-container">
        <div
          className="card border-secondary mt-3 mb-3"
          style={{
            maxWidth: "30rem",
            backgroundColor: "rgb(202, 203, 205)",
            height: "530px",
          }}
        >
          <div className="card-header" style={{ fontSize: "28px" }}>
            Silver Sponsor{" "}
            <Row style={{ fontSize: "20px", paddingLeft: "10px" }}>
              ($1,000)
            </Row>
          </div>
          <div className="card-body">
            <p className="card-text">
              <dl>
                <dt>For 1 Year, Corporate Name and Logo on:</dt>
                <dd>Website in Corporate Sponsors section</dd>
                <dt>Social Media</dt>
                <dd> Donor acknowledgement</dd>

                <dt>Community</dt>
                <dd>
                  4 Memberships
                  <br></br>4 T-shirts
                  <br></br>Table at all community event
                </dd>
              </dl>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Silver;
