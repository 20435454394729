import React from "react";
import { useCollection } from "../../hooks/useCollection";
import { useHistory } from "react-router";
import AdminTableRow from "./AdminTableRow";

const AdminTable = () => {
  const { isPending, error, documents } = useCollection("users");
  const history = useHistory();

  return (
    <div className="user-list">
      <h2>Board Members</h2>
      <button
        type="submit"
        className="btn btn-success"
        style={{ marginBottom: "15px" }}
        onClick={() => history.push("/createAdmin")}
      >
        Add Board Member
      </button>
      {isPending && <div>Loading Board Members...</div>}
      {error && <div>{error}</div>}
      {documents && (
        <table className="table table-hover">
          <thead>
            <tr className="table-dark">
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Position</th>
              <th></th>
            </tr>
          </thead>
          {documents.map(
            (user) =>
              user.board && (
                <tbody key={user.id} className="user-list-item">
                  <AdminTableRow
                    firstName={user.firstName}
                    lastName={user.lastName}
                    email={user.email}
                    position={user.position}
                    id={user.id}
                    user={user.user}
                  />
                </tbody>
              )
          )}
        </table>
      )}
    </div>
  );
};

export default AdminTable;
