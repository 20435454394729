import React from "react";

const ProductCard = ({
  productDescription,
  productName,
  productPrice,
  productQty,
  productStatus,
}) => {
  return (
    <div
      className="card border-warning mb-3"
      style={{ maxWidth: "20rem", margin: "20px" }}
    >
      <div className="card-header">{productName}</div>
      <div className="card-body">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="d-block user-select-none card-body"
          width="100%"
          height="200"
          focusable="false"
          role="img"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 318 180"
          style={{ fontSize: "1.125rem;", textAnchor: "middle;" }}
        >
          <rect width="100%" height="100%" fill="#868e96"></rect>
        </svg>

        {/* <h4 className="card-title">
          {firstName} {lastName}
        </h4> */}
        <p className="card-text">{productDescription}</p>
        <h2>${productPrice}</h2>
        {productStatus === "Available" && (
          <button
            type="submit"
            className="btn btn-warning"
            //   onClick={() => history.push("/board")}
          >
            Add to Card
          </button>
        )}
        {productStatus === "Pre-Order" && (
          <button
            type="submit"
            className="btn btn-danger"
            //   onClick={() => history.push("/board")}
          >
            Pre-Order
          </button>
        )}
        {productStatus === "Unavailable" && (
          <button
            type="submit"
            className="btn btn-light"
            disabled="true"
            //   onClick={() => history.push("/board")}
          >
            Product Unavailable
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
