import React, { useEffect, useState, useRef } from "react";
import { useSignup } from "../../hooks/useSignup";
import { useSignUpJob } from "../../hooks/useSignUpJob";
import { useSignupBuisRelation } from "../../hooks/useSignUpBuisRelation";
import emailjs from "@emailjs/browser";
import "./memberForm.css";
import { useHistory } from "react-router";
import { ImSpinner2 } from "react-icons/im";
import { useDocument } from "../../hooks/useDocument";
import { useFirestore } from "../../hooks/useFirestore";

function MemberForm() {
  const [currentMemberCount, setCurrentMemberCount] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorLabel, setErrorLabel] = useState("");
  const [membership, setMembership] = useState("");
  const [job, setJob] = useState("");
  const [jobFunction, setJobFunction] = useState("");
  const [business, setBusiness] = useState("");
  const [jobStatus, setJobStatus] = useState("");
  const [relation, setRelation] = useState("");
  const [gender, setGender] = useState("Pick One");
  const [identity, setIdentity] = useState("Pick One");
  const [race, setRace] = useState("Pick One");
  const [military, setMilitary] = useState("Pick One");
  const [branch, setBranch] = useState("N/A");
  const [loading, setLoading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [activeMilitary, setActiveMilitary] = useState(0);
  const [african, setAfrican] = useState(0);
  const [asian, setAsian] = useState(0);
  const [bi, setBi] = useState(0);
  const [caucasian, setCaucasian] = useState(0);
  const [female, setFemale] = useState(0);
  const [gay, setGay] = useState(0);
  const [genOther, setGenOther] = useState(0);
  const [genPerfer, setGenPerfer] = useState(0);
  const [hispanic, setHispanic] = useState(0);
  const [idOther, setIdOther] = useState(0);
  const [idPerfer, setIdPerfer] = useState(0);
  const [lesbian, setLesbian] = useState(0);
  const [male, setMale] = useState(0);
  const [nonBinary, setNonBinary] = useState(0);
  const [queer, setQueer] = useState(0);
  const [raceOther, setRaceOther] = useState(0);
  const [racePerf, setRacePerf] = useState(0);
  const [retiredMilitary, setRetiredMilitary] = useState(0);
  const [straight, setStraigt] = useState(0);
  const [trans, setTrans] = useState(0);
  const [profess, setProfess] = useState(0);
  const [assoc, setAssoc] = useState(0);

  const { signup } = useSignup();
  const { signupJob } = useSignUpJob();
  const { signupBuisRelation } = useSignupBuisRelation();
  const history = useHistory();

  const { document } = useDocument("memberInfo", "4oFlDJsmnbDGtPHbss26");
  const { updateDocument } = useFirestore("memberInfo");

  useEffect(() => {
    if (document) {
      console.log(document.bi);
      setAssoc(
        membership === "Associate" ? document.assoc + 1 : document.assoc
      );
      setProfess(
        membership === "Professional" ? document.profess + 1 : document.profess
      );
      setCurrentMemberCount(document.currentMemberCount + 1);
      setActiveMilitary(
        military ===
          "Currently serving as active duty, reserve, or national guard"
          ? document.activeMilitary + 1
          : document.activeMilitary
      );
      setRetiredMilitary(
        military === "Yes"
          ? document.retiredMilitary + 1
          : document.retiredMilitary
      );
      setAfrican(
        race === "African American" ? document.african + 1 : document.african
      );
      setAsian(race === "Asian" ? document.asian + 1 : document.asian);
      setBi(identity === "Bisexual" ? document.bi + 1 : document.bi);
      setCaucasian(
        race === "Caucasian" ? document.caucasian + 1 : document.caucasian
      );
      setFemale(gender === "Female" ? document.female + 1 : document.female);
      setGay(identity === "Gay" ? document.gay + 1 : document.gay);
      setGenOther(
        gender === "Other" ? document.genOther + 1 : document.genOther
      );
      setGenPerfer(
        gender === "Perfer Not to Say"
          ? document.genPerfer + 1
          : document.genPerfer
      );
      setHispanic(
        race === "Hispanic" ? document.hispanic + 1 : document.hispanic
      );
      setIdOther(
        identity === "Other" ? document.idOther + 1 : document.idOther
      );
      setIdPerfer(
        identity === "Perfer Not to Say"
          ? document.idPerfer + 1
          : document.idPerfer
      );
      setLesbian(
        identity === "Lesbian" ? document.lesbian + 1 : document.lesbian
      );
      setMale(gender === "Male" ? document.male + 1 : document.male);
      setNonBinary(
        identity === "Non-binary" ? document.nonBinary + 1 : document.nonBinary
      );
      setQueer(identity === "Queer" ? document.queer + 1 : document.queer);
      setRaceOther(
        race === "Other" ? document.raceOther + 1 : document.raceOther
      );
      setRacePerf(
        race === "Perfer Not to Say" ? document.racePerf + 1 : document.racePerf
      );
      setStraigt(identity === "Straight" ? document.straight + 1 : 0);
      setTrans(
        identity === "Transgender" ? document.trans + 1 : document.trans
      );
    }
  }, [document, gender, identity, membership, military, race]);

  const form = useRef();

  const updateMemberCount = async () => {
    let updatedCount = {
      currentMemberCount: currentMemberCount,
      activeMilitary,
      assoc,
      profess,
      african,
      asian,
      bi,
      caucasian,
      female,
      gay,
      genOther,
      genPerfer,
      hispanic,
      idOther,
      idPerfer,
      lesbian,
      male,
      nonBinary,
      queer,
      raceOther,
      racePerf,
      retiredMilitary,
      straight,
      trans,
    };
    console.log(updatedCount);
    await updateDocument("4oFlDJsmnbDGtPHbss26", updatedCount);
  };

  const handleSendEmail = (e) => {
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID_NEW_MEMBER,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then((error) => {
        console.log(error.text);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email !== "") {
      if (password !== "") {
        if (firstName !== "") {
          if (lastName !== "") {
            if (membership !== "") {
              if (race !== "Pick One") {
                if (gender !== "Pick One") {
                  if (military !== "Pick One") {
                    if (identity !== "Pick One") {
                      if (membership === "Associate") {
                        if (relation !== "") {
                          if (address !== "") {
                            if (city !== "") {
                              if (state !== "") {
                                if (zipCode !== "") {
                                  if (phoneNumber !== "") {
                                    if (relation === "business") {
                                      if (business !== "") {
                                        setLoading(true);
                                        await signupBuisRelation(
                                          email,
                                          password,
                                          firstName,
                                          lastName,
                                          membership,
                                          gender,
                                          military,
                                          branch,
                                          race,
                                          identity,
                                          relation,
                                          business,
                                          phoneNumber,
                                          address,
                                          city,
                                          state,
                                          zipCode,
                                          currentMemberCount
                                        );
                                        updateMemberCount();
                                        handleSendEmail();
                                        setLoading(false);
                                        history.push("/Dashboard");
                                      } else {
                                        setErrorLabel(
                                          "ERROR: Please add your business name!"
                                        );
                                      }
                                    } else {
                                      setLoading(true);
                                      await signup(
                                        email,
                                        password,
                                        firstName,
                                        lastName,
                                        membership,
                                        gender,
                                        military,
                                        branch,
                                        race,
                                        identity,
                                        relation,
                                        phoneNumber,
                                        address,
                                        city,
                                        state,
                                        zipCode,
                                        currentMemberCount
                                      );
                                      updateMemberCount();
                                      handleSendEmail();
                                      setLoading(false);
                                      history.push("/Dashboard");
                                    }
                                  } else {
                                    setErrorLabel(
                                      "ERROR: Please add a phone number or N/A"
                                    );
                                  }
                                } else {
                                  setErrorLabel(
                                    "ERROR: Please add a zip code or N/A"
                                  );
                                }
                              } else {
                                setErrorLabel(
                                  "ERROR: Please add a state or N/A"
                                );
                              }
                            } else {
                              setErrorLabel("ERROR: Please add a city or N/A");
                            }
                          } else {
                            setErrorLabel(
                              "ERROR: Please add an address or N/A"
                            );
                          }
                        } else {
                          setErrorLabel(
                            "ERROR: Please select how you currently support the LGBTQ Law Enforcement & Criminal Justice Professionals!"
                          );
                        }
                      } else {
                        if (jobStatus !== "") {
                          if (jobFunction !== "") {
                            if (job !== "") {
                              setLoading(true);
                              await signupJob(
                                email,
                                password,
                                firstName,
                                lastName,
                                membership,
                                gender,
                                military,
                                branch,
                                race,
                                identity,
                                job,
                                jobStatus,
                                jobFunction,
                                phoneNumber,
                                address,
                                city,
                                state,
                                zipCode,
                                currentMemberCount
                              );
                              console.log("here");
                              updateMemberCount();
                              handleSendEmail();
                              setLoading(false);
                              history.push("/Dashboard");
                            } else {
                              setErrorLabel("ERROR: Please enter your agency!");
                            }
                          } else {
                            setErrorLabel(
                              "ERROR: Please select your current job title!"
                            );
                          }
                        } else {
                          setErrorLabel(
                            "ERROR: Please select your current job status!"
                          );
                        }
                      }
                    } else {
                      setErrorLabel("ERROR: Please select your identity!");
                    }
                  } else {
                    setErrorLabel("ERROR: Please select military status!");
                  }
                } else {
                  setErrorLabel("ERROR: Please select gender!");
                }
              } else {
                setErrorLabel("ERROR: Please select race!");
              }
            } else {
              setErrorLabel("ERROR: Please select membership type!");
            }
          } else {
            setErrorLabel("ERROR: Please enter your last name!");
          }
        } else {
          setErrorLabel("ERROR: Please enter your first name!");
        }
      } else {
        setErrorLabel("ERROR: Please enter a password!");
      }
    } else {
      setErrorLabel("ERROR: Please enter a valid email address!");
    }
  };

  return (
    <div className="card memberForm">
      <div className="card-body">
        <h4 className="card-title">Join Now</h4>
        <form ref={form} onSubmit={handleSendEmail}>
          <fieldset>
            <div className="form-group">
              <label className="form-label mt-4">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="user_firstName"
                placeholder="First Name (Required)"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="user_lastName"
                placeholder="Last Name (Required)"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email (Required)"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label className="form-label mt-4">Password</label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Password (Required)"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-4">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phonenumber"
                placeholder="Phone Number (Required or N/A)"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-4">Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="Address (Required or N/A)"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-4">City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="City (Required or N/A)"
                onChange={(e) => setCity(e.target.value)}
                value={city}
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-4">State</label>
              <input
                type="text"
                className="form-control"
                id="state"
                placeholder="State (Required or N/A)"
                onChange={(e) => setState(e.target.value)}
                value={state}
              />
            </div>

            <div className="form-group">
              <label className="form-label mt-4">Zip Code</label>
              <input
                type="text"
                className="form-control"
                id="zipcode"
                placeholder="Zip Code (Required or N/A)"
                onChange={(e) => setZipCode(e.target.value)}
                value={zipCode}
              />
            </div>

            <fieldset
              className="form-group-test2"
              onChange={(e) => setMembership(e.target.value)}
            >
              <legend className="mt-4">Select Membership Type:</legend>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios1"
                  id="optionsRadios1"
                  value="Professional"
                />
                <label className="form-check-label">
                  Professional Membership - past or present full-time law
                  enforcement officer or full-time employee of an agency of the
                  criminal justice system
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionsRadios1"
                  id="optionsRadios2"
                  value="Associate"
                />
                <label className="form-check-label">
                  Associate Membership - open to anyone!
                </label>
              </div>
            </fieldset>

            {membership === "Professional" && (
              <div className="form-group">
                <label className="form-label mt-4">
                  What agency do you work for?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="job"
                  placeholder="Your answer (Required)"
                  onChange={(e) => setJob(e.target.value)}
                  value={job}
                />
                <label className="form-label mt-4">
                  What is your current job function?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="jobFunction"
                  placeholder="Your answer (Required)"
                  onChange={(e) =>
                    setJobFunction(e.target.value) && setBusiness("")
                  }
                  value={jobFunction}
                />

                <fieldset
                  className="form-group-test"
                  onChange={(e) => setJobStatus(e.target.value)}
                >
                  <legend className="mt-4">What is your current status:</legend>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="optionsRadios2"
                      id="optionsRadios3"
                      value="Active"
                    />
                    <label className="form-check-label">Active</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="optionsRadios2"
                      id="optionsRadios4"
                      value="Retired"
                    />
                    <label className="form-check-label">Retired</label>
                  </div>
                </fieldset>
              </div>
            )}

            {membership === "Associate" && (
              <div className="form-group">
                <fieldset
                  className="form-group"
                  onChange={(e) => setRelation(e.target.value)}
                >
                  <legend className="mt-4">
                    How do you currently support the LGBTQ Law Enforcement &
                    Criminal Justice Professionals?
                  </legend>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="optionsRadios3"
                      id="optionsRadios5"
                      value="spouse"
                    />
                    <label className="form-check-label">
                      Spouse or Significant Other
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="optionsRadios3"
                      id="optionsRadios6"
                      value="friend"
                    />
                    <label className="form-check-label">Friend or Family</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="optionsRadios3"
                      id="optionsRadios7"
                      value="business"
                    />
                    <label className="form-check-label">
                      Supportive Business
                    </label>
                  </div>
                </fieldset>
              </div>
            )}
            {relation === "business" && (
              <div className="form-group">
                <label className="form-label mt-4">
                  What is the name of your business?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="business"
                  placeholder="Your answer (Required)"
                  onChange={(e) => setBusiness(e.target.value)}
                  value={business}
                />
              </div>
            )}

            <h3 style={{ marginTop: "18px" }}>Demographic Information </h3>
            <p>
              To help us understand more about the demographics of our
              organization, please answer the following questions. This section
              is not required for membership consideration. Responses will not
              be shared outside of the membership committee or with the
              membership as a whole.{" "}
            </p>

            <div className="form-group">
              <label className="form-label mt-4">Gender</label>
              <select
                type="text"
                className="form-control"
                id="business"
                placeholder="Your answer (Required)"
                onChange={(e) => setGender(e.target.value)}
                value={gender}
              >
                <option>Pick One</option>
                <option>Male</option>
                <option>Female</option>
                <option>Perfer Not to Say</option>
                <option>Other</option>
              </select>
            </div>

            <div className="form-group">
              <label className="form-label mt-4">How do you identify</label>
              <select
                type="text"
                className="form-control"
                id="business"
                placeholder="Your answer (Required)"
                onChange={(e) => setIdentity(e.target.value)}
                value={identity}
              >
                <option>Pick One</option>
                <option>Bisexual</option>
                <option>Gay</option>
                <option>Lesbian</option>
                <option>Non-binary</option>
                <option>Straight</option>
                <option>Transgender</option>
                <option>Queer</option>
                <option>Perfer Not to Say</option>
                <option>Other</option>
              </select>
            </div>

            <div className="form-group">
              <label className="form-label mt-4">Race</label>
              <select
                type="text"
                className="form-control"
                id="business"
                placeholder="Your answer (Required)"
                onChange={(e) => setRace(e.target.value)}
                value={race}
              >
                <option>Pick One</option>
                <option>African American</option>
                <option>Asian</option>
                <option>Caucasian</option>
                <option>Hispanic</option>
                <option>Perfer Not to Say</option>
                <option>Other</option>
              </select>
            </div>

            <div className="form-group">
              <label className="form-label mt-4">
                Are you a military veteran?
              </label>
              <select
                type="text"
                className="form-control"
                id="business"
                placeholder="Your answer (Required)"
                onChange={(e) => setMilitary(e.target.value)}
                value={military}
              >
                <option>Pick One</option>
                <option>Yes</option>
                <option>No</option>
                <option>
                  Currently serving as active duty, reserve, or national guard
                </option>
              </select>
            </div>

            {military !== "Pick One" && military !== "No" && (
              <div className="form-group">
                <label className="form-label mt-4">
                  What branch did you serve or are you currently serving in?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="business"
                  placeholder="Your answer (Required)"
                  onChange={(e) => setBranch(e.target.value)}
                  value={branch}
                />
              </div>
            )}

            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading && (
                <ImSpinner2
                  icon="spinner"
                  className="spinner"
                  style={{
                    marginRight: "5px",
                  }}
                />
              )}
              {loading && <span>Creating Profile</span>}
              {!loading && <span>Register</span>}
            </button>
          </fieldset>
          <label>{errorLabel}</label>
        </form>
      </div>
    </div>
  );
}

export default MemberForm;
